import React, {useState, useEffect} from 'react';
import {Dimmer, Header, Loader, Modal} from 'semantic-ui-react';
import './styles.css';
import DocViewer from "@cyntler/react-doc-viewer";
import { isMobile } from 'react-device-detect';

export default function ModalPDF(props) {
    const {open, handleOpen, handleClose, urlPDF, headerText} = props;
    const [defaultZoom, setDefaultZoom] = useState(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const docs = [
        { uri: urlPDF }
    ];

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobile) {
            setDefaultZoom(1.3);
        } else {
            setDefaultZoom(1.0);
        }
    }, [windowWidth]);

    const loaderPDF = () => (
        <Dimmer active>
            <Loader></Loader>
        </Dimmer>
    );

    return (
        <Modal
            closeIcon
            dimmer
            centered
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            className="modal-pdf"
        >
            <Header content={headerText}/>
            <Modal.Content className="pdf-content">
                <DocViewer
                    documents={docs}
                    config={{
                        header: {
                            disableHeader: true,
                            disableFileName: true,
                            retainURLParams: true,
                        },
                        pdfZoom: {
                            defaultZoom: defaultZoom,
                            zoomJump: 0.1,
                        },
                        loadingRenderer: {
                            overrideComponent: loaderPDF,
                        },
                        pdfVerticalScrollByDefault: true
                    }}
                />
            </Modal.Content>
        </Modal>
    );
}
