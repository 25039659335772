import React, {useEffect} from 'react';
import {Header, Button, Dimmer, Loader} from 'semantic-ui-react';
import {useSelector} from 'react-redux';
import { Link } from 'react-router-dom';

import './styles.css';
import AddressCard from '../../components/AddressCard';
import CardSummary from '../../components/CardSummary/CardSummary';
import CheckoutSteps, { CHECKOUT_STEP_SHIPPING } from '../../components/CheckoutSteps';
import {getTotalPrice, hasOnlyVirtuals} from '../Cart/reducer';
import {getAddressFields} from "../../components/AddressCard/reducer";
import ShippingCard from "../../components/ShippingCard";
import ConfirmOrderButton from "../../components/ConfirmOrderButton";
import {getPlaceOrderInProgress} from "../Orders/reducer";
import {checkoutOrderPlaced} from "../Orders/actions";

export default function CheckoutStepShipping(props) {

    const {dispatch, userLocation, userData, mixpanel} = props;

    const total_products = useSelector((state) => getTotalPrice(state.cart));
    const only_virtuals = useSelector((state) => hasOnlyVirtuals(state.cart));
    const total_price = useSelector((state) => getTotalPrice(state.cart));
    const addressFields = useSelector((state) => getAddressFields(state.address));
    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));

    useEffect(() => {
        if (mixpanel)
            mixpanel.track('Checkout Address', { ...userLocation, ...userData, ...addressFields });
        window.scrollTo(0, 0);
        dispatch(checkoutOrderPlaced({})); // сбрасываем зависшие сессии по созданию заказа
    }, []);

    const button_text = total_price ? "К выбору способа оплаты" : "Подтвердить заказ";

    return (
        <div>
            <Header textAlign="center">Оформление заказа</Header>
            <CheckoutSteps step={CHECKOUT_STEP_SHIPPING} />
            {<Dimmer active={ getLoading === 1 } inverted>
                <Loader inverted> Создаем заказ ...</Loader>
            </Dimmer>}
            <AddressCard {...props}/>
            { !only_virtuals ? <ShippingCard /> : '' }
            <CardSummary total_products={total_products} />
            { addressFields.hasErrors
                ? <Button className="checkout-address-buttons-button disabled" color="green" disabled={true}>{button_text}</Button>
                : (total_price
                    ? <Link to="/checkout-step-payment">
                        <Button className="checkout-address-buttons-button goto-payment-options" color="green">К выбору способа оплаты</Button>
                      </Link>
                    : <ConfirmOrderButton
                        addClassName="checkout-address-buttons-button"
                        buttonText="Подтвердить заказ"
                        pathToRoute="/checkout-step-confirm"
                        {...props}
                    />)
            }
        </div>
    );
}



