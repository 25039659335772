import React, { useEffect, useState } from 'react';
import {useSelector} from 'react-redux';
import { Button, Header,  Divider } from 'semantic-ui-react';

import { fetchTaskOptions, fetchTasks, TASKS_PAGE_COUNT, setTaskFilter, setTaskOrderBy } from './actions';
import { getTasksFetching, getTasks, getTasksHasMore, getTaskFilter, getTaskOptions, getTaskOrderBy } from './reducer';
import TasksList from '../../components/TasksList';
import InfiniteView from "../../components/InfiniteView";

import {isArray} from "lodash";
import ModalFilter from "../../components/ModalFilter/ModalFilter";
import "./style.css"
import { setWindowScrollPosition } from "../../components/WindowDimensions";

export default function Tasks(props) {

    const {routeAction, dispatch, token, userLocation, userData, mixpanel, isPageReloaded} = props;

    const tasks = useSelector((state) => getTasks(state.tasks));
    const loading = useSelector((state) => getTasksFetching(state.tasks));
    const hasMore = useSelector((state) => getTasksHasMore(state.tasks));
    const taskOptions = useSelector((state) => getTaskOptions(state.tasks));
    let filter = useSelector((state) => getTaskFilter(state.tasks));
    let orderBy = useSelector((state) => getTaskOrderBy(state.tasks));

    const [modalFilterOpen, setModalFilterOpen] = useState(false);

    if (isArray(taskOptions.filter)) {
       if (filter.length === 0)
           filter = taskOptions.filter.map(
               (item, index) => ({
                   'key': item.key,
                   'value': item.value,
                   'disabled': item.disabled,
                   'label': taskOptions.statuses[item.key]
               })
           );
       else
           filter = taskOptions.filter.map(
               (item, index) => ({
                   'key': item.key,
                   'value': filter[index].value,
                   'disabled': item.disabled,
                   'label': taskOptions.statuses[item.key]
               })
       );
    }

    if (orderBy && orderBy.length === 0 && isArray(taskOptions.orderby)) {
        orderBy = taskOptions.orderby;
    }

    useEffect(() => {
       if ( routeAction === 'PUSH' || isPageReloaded ) {
          if (token) {
              dispatch(fetchTaskOptions(token));
              readTasks(1, filter, orderBy);
          }
          setWindowScrollPosition();
      }
      if (mixpanel)
          mixpanel.track('Tasks List View', { ...userLocation, ...userData, ...filter, ...orderBy, routeAction });
    }, []);

    useEffect(() => {
        if (isPageReloaded) {
            setWindowScrollPosition();
        }
    }, [props]);

    function loadTasks() {
        readTasks(Math.round(tasks.length / TASKS_PAGE_COUNT) + 1, filter, orderBy);
     }

    function readTasks(page, filter, orderby) {
        dispatch(fetchTasks(
            token,
        {
                page,
                per_page: TASKS_PAGE_COUNT,
                orderby: encodeURIComponent(JSON.stringify(orderby)),
                filter: encodeURIComponent(JSON.stringify(filter)),
            },
        ));
    }

    function saveFilter (newFilter, newOrderBy){
        dispatch(setTaskFilter(newFilter));
        dispatch(setTaskOrderBy(newOrderBy));
        readTasks(1, newFilter, newOrderBy);
    }

    if (!isArray(tasks)) return;

    const buttonFilter = () =>
      (!token ? '' :
          <Button
              className="filter-button"
              onClick={()=> setModalFilterOpen(true)}
              compact
          >
              Фильтры
          </Button>
      );

    return (
      <div className="tasks-view">
          <Header className="tasks-header" textAlign="center">
              <div className="tasks-header-text">Задачи</div>
              {buttonFilter()}
          </Header>
          <Divider fitted />
          <InfiniteView
              dataLength={tasks.length}
              nextFetch={loadTasks}
              hasMore={hasMore}
              dataList={(
                  <TasksList
                      tasks={tasks}
                      {...props}
                  />
              )}
              mustAuth={true}
              isAuth={!!token}
              isLoading={loading}
              loaderText="Загружаем задачи ..."
              zeroText="Задачи отсутствуют"
          />
          <ModalFilter
                  open={modalFilterOpen}
                  handleOpen={()=> setModalFilterOpen(true)}
                  handleClose={()=> setModalFilterOpen(false)}
                  callback={saveFilter}
                  filter={structuredClone(filter)}
                  orderby={structuredClone(orderBy)}
                  mixpanel={mixpanel}
          />
      </div>
    );
}
