import React from 'react';
import TaskCard from './TaskCard/TaskCard';
import {Header} from "semantic-ui-react";

export default function TasksList(props) {
    const { tasks, title } = props;
    return (
        <div>
            <Header textAlign="center">{title}</Header>
            {tasks ? tasks.map((element) => (
                <TaskCard
                    key={element.id}
                    task={element}
                    image="yes"
                    showLoader
                    {...props}
                />
            )) : ''}
        </div>
    );
}