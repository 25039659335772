import React, {useState} from 'react'
import InfiniteView from "../../components/InfiniteView";
import InstructionsCard from "../../components/InstructionsCard";
import ModalPDF from "../../components/ModalPDF/ModalPDF";
import ModalPlayer from "../../components/ModalPlayer";

export default function Instructions(){
    const [modalPDF, setModalPDF] = useState(false)
    const [modalActive, setModalActive] = useState(false);
    const [videoURL, setVideoURL] = useState('');
    const [headerText, setHeaderText] = useState('');

    const modalpdf = () =>
        (
            <ModalPDF
                open={modalPDF}
                headerText="Инструкция"
                urlPDF="https://e-feed.ru/wp-content/uploads/media/servicer-read-me.pdf"
                handleOpen={() => setModalPDF(true)}
                handleClose={() => setModalPDF(false)}
            />
        )
    function handlePDFClick() {
        setModalPDF(true);
    }
    const modalPlayer = () =>(
        <ModalPlayer
            active={modalActive}
            setActive={setModalActive}
            url={videoURL}
            headerText={headerText}
        />
    )
    function handlePlayerClick(url, headerText) {
        setModalActive(true);
        setVideoURL(url);
        setHeaderText(headerText);
    }


    const cardInstructions = () => (
        [<InstructionsCard
            hrefForTitle={handlePDFClick}
            title="Руководство пользователя (PDF)"
            date="27.04.2024"
            duration="15 мин"
            description="Документальная инструкция по пользованию приложением содержит в себе 7 подробных информационных глав с иллюстративными примерами.">
        </InstructionsCard>,
            <InstructionsCard
                hrefForTitle={() => handlePlayerClick('https://e-feed.ru/wp-content/uploads/media/login-to-account.mp4', 'Вход в учетную запись')}
                title="Вход в учетную запись"
                date="27.04.2024"
                duration="1 мин"
                description="Видеоинтструкция с подробным описанием того, как зайти в аккаунт под своим пользователем.">
            </InstructionsCard>,
            <InstructionsCard
                hrefForTitle={()=>handlePlayerClick('https://e-feed.ru/wp-content/uploads/media/navigate-panels.mp4','Панели навигации')}
                title="Панели навигации"
                date="27.04.2024"
                duration="1 мин"
                description="Видеоинструкция с подробным описанием разделов приложения и полей, на которых они находятся.">
            </InstructionsCard>,
            <InstructionsCard
                hrefForTitle={()=>handlePlayerClick('https://e-feed.ru/wp-content/uploads/media/order-processing.mp4','Обработка заказов')}
                title="Обработка заказов"
                date="27.04.2024"
                duration="2 мин"
                description='Видеоинструкция с подробным описанием того,  как принять заказ, изменить статус заказа в ходе работы, сопроводить заказ до статуса "завершена".'>
            </InstructionsCard>,
            <InstructionsCard
                hrefForTitle={()=>handlePlayerClick('https://e-feed.ru/wp-content/uploads/media/fix-works.mp4','Ввод выполненных работ')}
                title="Ввод выполненных работ"
                date="27.04.2024"
                duration="2 мин"
                description='Видеоинструкция с подробным описанием того, как заполнить шаги выполненных работ, приложить необходимые файлы, написать заключение, и отправить заказ в статус "на проверке".'>
            </InstructionsCard>
        ]
    )

    return (
        <>
        <InfiniteView
            headerTitle="Инструкции"
            dataLength={cardInstructions().length}
            dataList={cardInstructions()}
            mustAuth={false}
            loaderText="Загружаем инструкции ..."
            zeroText="Инструкции отсутствуют"
        />
        {modalpdf()}
            {modalActive ? modalPlayer() : undefined}
        </>
    );
}