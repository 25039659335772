import React, { Component } from 'react';
import { Header } from 'semantic-ui-react';
import ProductCard from './ProductCard';

class ProductsList extends Component {
  render() {
    const { products, title } = this.props;
    const list = products.map((element, index) => (
      <ProductCard
        key={element.id}
        id={element.id}
        src={element.images[0].src}
        name={element.name}
        price={element.price}
        categories={element.categories}
        shortDescription={element.short_description}
        categoryId={element.categories[0].id}
        has_options={element.has_options}
        virtual={element.virtual}
        {...this.props}
      />
    ));

    return (
      <div>
        <Header textAlign="center">{title}</Header>
        {list}
      </div>
    );
  }
}

export default ProductsList;
