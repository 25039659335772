import React, {useEffect} from 'react';
import {Header, Button, Card, Loader, Dimmer} from 'semantic-ui-react';
import {useSelector} from 'react-redux';
import {Link}  from 'react-router-dom';

import './styles.css';
import CardSummary from '../../components/CardSummary/CardSummary';
import CheckoutSteps, { CHECKOUT_STEP_PAYMENT } from '../../components/CheckoutSteps';
import {getTotalPrice, hasOnlyVirtuals} from '../Cart/reducer';
import { getShippingCost, getShippingMethod, getShippingType } from '../../components/ShippingCard/reducer';
import PaymentOptions from './PaymentOptions';

import { getAddressFields} from '../../components/AddressCard/reducer';
import { getPaymentMethod } from './reducer';
import CouponCard from '../../components/CouponCard';
import ConfirmOrderButton from "../../components/ConfirmOrderButton";
import {getPlaceOrderInProgress} from "../Orders/reducer";
import {PAYMENT_OPTION_BANK} from "./actions";

export default function CheckoutStepPayment(props) {

  const {dispatch, userData, userLocation, mixpanel} = props;

  const total_products = useSelector((state) => getTotalPrice(state.cart));
  const total_shipping = useSelector((state) => getShippingCost(state.shipping));

  const only_virtuals = useSelector((state) => hasOnlyVirtuals(state.cart));
  const shipping_type = only_virtuals? '' : useSelector((state) => getShippingType(state.shipping));
  const shipping_method = only_virtuals ? '' : useSelector((state) => getShippingMethod(state.shipping));

  const address_fields = useSelector((state) => getAddressFields(state.address));
  const payment_option = useSelector((state) => getPaymentMethod(state.payment));
  const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));

  useEffect(() => {
      if (mixpanel)
          mixpanel.track('Checkout Payment', { ...userLocation, ...userData, payment_option, address_fields, total_products, total_shipping });
      window.scrollTo(0, 0);
  }, []);

  return (
      <div>
        <Header textAlign="center">Выбор способа оплаты</Header>
        <CheckoutSteps step={CHECKOUT_STEP_PAYMENT} />
        <Dimmer active={ getLoading === 1 } inverted>
            <Loader inverted> Создаем заказ ...</Loader>
        </Dimmer>
        <CardSummary
          total_products={total_products}
          total_shipping={total_shipping}
          show_shipping = {!only_virtuals}
        />
        <CouponCard />
        <Card centered className="delivery-details-card">
          <Card.Header>Способ оплаты</Card.Header>
          <Card.Content>
            <PaymentOptions
                paymentOption={payment_option}
                shippingMethod={shipping_method}
                shippingType={shipping_type}
                emailField={address_fields.email ?? ''}
                dispatch={dispatch}
            />
          </Card.Content>
        </Card>
        <div className="checkout-payment-buttons">
          <Link to="/checkout-step-shipping">
            <Button secondary className="checkout-payment-buttons-button button-back" floated="right">
              Назад
            </Button>
          </Link>
          <ConfirmOrderButton
              addClassName="checkout-payment-buttons-button"
              buttonText="Подтвердить заказ"
              pathToRoute="/checkout-step-confirm"
              disabled={ (typeof address_fields.email === 'undefined' || address_fields.email.length === 0) &&
                  payment_option === PAYMENT_OPTION_BANK }
              {...props}
          />
        </div>
      </div>
    );
  }



