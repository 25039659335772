import { combineReducers } from 'redux';
import {
  REQUEST_USER_DATA, RECEIVE_USER_DATA, REQUEST_TOKEN, RECEIVE_TOKEN, DROP_TOKEN,
} from './actions';


const userData = (state = {}, action = {}) => {
  switch (action.type) {
    case REQUEST_USER_DATA:
      return state;
    case RECEIVE_USER_DATA:
      return action.data;
    default:
      return state;
  }
};

const isUserDataFetching = (state = false, action = {}) => {
  switch (action.type) {
    case REQUEST_USER_DATA:
      return true;
    case RECEIVE_USER_DATA:
      return false;
    default:
      return state;
  }
};

const userToken = (state = [], action = {}) => {
  switch (action.type) {
    case REQUEST_TOKEN:
      return state;
    case DROP_TOKEN:
    case RECEIVE_TOKEN:
      return action.data;
    default:
      return state;
  }
};

const isUserTokenFetching = (state = false, action = {}) => {
  switch (action.type) {
    case REQUEST_TOKEN:
      return true;
    case RECEIVE_TOKEN:
    case DROP_TOKEN:
      return false;
    default:
      return state;
  }
};

export const getUserData = (state) => state.userData;
export const getAuthToken = (state) => state.userToken.token;
export const getUserFetching = (state) => state.isUserTokenFetching || state.isUserDataFetching;
export const getUserLoggedIn = (state) => state.userToken.loggedIn;
export const getUserLoginError = (state) => (state.userToken.message ? state.userToken.message : '');
export const getUserDisplayName = (state) => state.userToken.userDisplayName;

const userLogin = combineReducers({
  userData,
  isUserDataFetching,
  userToken,
  isUserTokenFetching,
});

export default userLogin;
