import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Header } from 'semantic-ui-react';
import {upperCase} from 'lodash';

import { fetchCategories, CATEGORIES_PAGE_COUNT } from './actions';
import {
  getCategories, getCategoriesFetching, getCategoriesHasMore, getCategoriesParent,
} from './reducer';
import CategoriesList from './CategoriesList';
import { useParams } from 'react-router';
import InfiniteView from "../../components/InfiniteView";
import {setWindowScrollPosition} from "../../components/WindowDimensions";

export default function Categories(props) {

  const {userData, userLocation, mixpanel, dispatch, routeAction, isPageReloaded} = props;

  const matchParams = useParams();
  const categId = matchParams.categId;
  const hasMore = useSelector((state) => getCategoriesHasMore(state.categories));
  const loading = useSelector((state) => getCategoriesFetching(state.categories));
  const categories = useSelector((state) => getCategories(state.categories));
  const parentCat = useSelector((state) => getCategoriesParent(state.categories));

  const readCategories = (parent, page) => {
    dispatch(fetchCategories(parent, {
      page,
      parent,
      per_page: CATEGORIES_PAGE_COUNT,
      orderby: 'name',
      order: 'asc',
    }));
  };

  const loadCategories = () => {
    const nextPage = Math.round(categories.length / CATEGORIES_PAGE_COUNT) + 1;
    readCategories(parentCat.id, nextPage);
  };

  useEffect(() => {
    if ( routeAction === 'PUSH' || isPageReloaded ) {
      setWindowScrollPosition();
      readCategories(categId, 1);
    }
    if (mixpanel)
      mixpanel.track('Categories View', { ...userLocation, ...userData, categId });
  }, [categId]);

  useEffect(() => {
    if (isPageReloaded) {
      setWindowScrollPosition();
    }
  }, [props]);

  const items =
      categories.filter(
          item => [String(item.parent), String(item.id)].includes(String(categId))
  );

  return (
      <InfiniteView
          headerTitle={`Категории ${upperCase(parentCat.name)}`}
          dataLength={items.length}
          nextFetch={loadCategories}
          hasMore={hasMore}
          dataList={(
              <CategoriesList
                  categories={items}
                  id={categId}
              />
          )}
          mustAuth={false}
          isLoading={loading}
          loaderText="Загружаем категории ..."
          zeroText="Категории отсутствуют"
      />
  );
}



