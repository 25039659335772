const {toastr} = require("react-redux-toastr");

function tAddEventListener(e, t, n) {
    e.addEventListener ? e.addEventListener(t, n, !1) : e.attachEvent && e.attachEvent('on' + t, n);
}

function doPay(e, callback) {
    if (e.OrderId || (e.OrderId = (new Date).getTime()), e.DATA) {
            if ("string" == typeof e.DATA) try {
                e.DATA = JSON.parse(e.DATA)
            } catch (t) {
                let n = {},
                    i = e.DATA.split('|');
                if (i.length > 0) {
                    for (let o = 0, a = i.length; o < a; o++) {
                        let r = i[o].split('='),
                            l = '';
                        r.length > 0 && (l = r[0].trim()) && (n[l] = r[1])
                    }
                    e.DATA = n
                } else delete e.DATA
            }
            e.DATA.connection_type = 'Widget2.0'
        } else e.DATA = {
            connection_type: 'Widget2.0'
        };
        if (e.Receipt) {
            if ('string' == typeof e.Receipt && (e.Receipt = JSON.parse(e.Receipt)) &&
                !(e.Phone || e.Email || e.Receipt.Email || e.Receipt.Phone))
                return void toastr.error('Укажите Email или телефон для получения чека!');
            (e.Email || e.Receipt.Email) && (e.Receipt.Email = e.Email || e.Receipt.Email), (e.Phone || e.Receipt.Phone) && (e.Receipt.Phone = e.Phone || e.Receipt.Phone)
        }
        delete e.Name, delete e.Email, delete e.Phone;

        let s = "https://securepay.tinkoff.ru/v2/Init",
            d = new XMLHttpRequest;

        d.open("POST", s, !0),
            d.setRequestHeader('Content-Type', 'application/json'),
                d.onreadystatechange = function() {
                    if (4 == d.readyState && 200 == d.status) {
                        let t = JSON.parse(d.responseText);

                        if (0 == t.ErrorCode && t.Success) {
                            let n = t.PaymentURL;
                            if (e.Frame) {
                                callback(n)
                            } else {
                                window.location.href = n
                            }
                            e.HandleLoading();
                        } else {
                            toastr.error("Ошибка открытия платежной формы банка! Код: " + t.ErrorCode + ". " + t.Message + " " + t.Details);
                            e.HandleClose();
                        }
                    }
                },
                    d.send(JSON.stringify(e));
}

module.exports = function (e, callback) {
    let t = e.email ?? "",
        n = e.phone ?? "",
        i = e.name ?? "",
        o = e.receipt ?? "",
        a = e.DATA ?? "",
        r = {
            TerminalKey: e.terminalkey,
            Amount: 100 * e.amount.replace(/,/gi, '.'),
            OrderId: e.order ? e.order : "",
            Description: e.description ? e.description : "",
            Frame: 'true' == e.frame.toLowerCase(),
            Language: e.language.toLowerCase(),
            Phone: n,
            Email: t,
            Name: i,
            SuccessURL: e.successurl,
            HandleClose: e.handleclose,
            HandleLoading: e.handleloading,
        },
        l = '';
    return t && (l = 'Email=' + t), n && (l && (l += '|'), l = l + 'Phone=' + n), i && (l && (l += '|'), l = l + 'Name=' + i), a ? r.DATA = a + (l ? '|' + l : '') : l && (r.DATA = l), o && (r.Receipt = JSON.parse(o)), doPay(r, callback)
}
