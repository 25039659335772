import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import {Loader, Container, Header, Dimmer} from 'semantic-ui-react';

import {
    fetchSearch,
    receiveSearch,
    SEARCH_CONTEXT_ORDERS, SEARCH_CONTEXT_PRODUCTS,
    SEARCH_CONTEXT_TASKS,
    SEARCH_CONTEXTS,
    SEARCH_PAGE_COUNT
} from './actions';
import {
    getSearchFetching,
    getSearch,
    getHasMore,
    getToContext,
    getFromContext
} from './reducer';
import ProductsList from '../../components/ProductsList';

import {useParams} from 'react-router';
import OrdersList from "../../components/OrdersList";

import "./style.css"
import TasksList from "../../components/TasksList";
import InfiniteView from "../../components/InfiniteView";
import {setWindowScrollPosition} from "../../components/WindowDimensions";

export function isSearchView(pathname) {
    return pathname.indexOf('search') !== -1;
}
export default function Search(props) {

  const {routeAction, location, dispatch, token, userLocation, userData, mixpanel, isPageReloaded} = props;

  const {search} = useParams();

  const loading = useSelector((state) => getSearchFetching(state.search));
  const searchItems = useSelector((state) => getSearch(state.search));
  const hasMore = useSelector((state) => getHasMore(state.search));
  const fromContext = useSelector((state) => getFromContext(state.search));
  const searchContext = useSelector((state) => getToContext(state.search));

  const context = SEARCH_CONTEXTS.find(obj => obj.value === fromContext);
  const contextTitle = _.isObject(context) ? context.title : '';
  const title = `Результаты поиска '${search}' ${contextTitle}`;

  useEffect(() => {
      if ( routeAction === 'PUSH' || isPageReloaded ) {
              window.scrollTo(0, 0);
              dispatch(receiveSearch(searchContext, 1, []));
              readSearch(1, searchContext);
          }
          if (mixpanel)
              mixpanel.track('Search List View',
                  {...userLocation, ...userData, search, searchContext, fromContext, routeAction, ...location});
  }, [search, searchContext]);

  useEffect(() => {
        if (isPageReloaded) {
            setWindowScrollPosition();
        }
  }, [props]);

  const loadMore = () => readSearch(Math.round(searchItems.length / SEARCH_PAGE_COUNT) + 1, searchContext);

  const readSearch = (page, context)  => {
    dispatch(fetchSearch(
        token,
        context,
        {
              search,
              page,
              per_page: SEARCH_PAGE_COUNT,
              order: 'asc',
              orderby: 'id',
        }
    ));
  }

  if (!navigator.onLine)
      return (<Container><p>Нет подключения к Интернету</p></Container>);

  const loaderText = `Ищем '${search}' ${contextTitle}...`;
  const headerTitle = ` Результаты поиска '${search}' ${contextTitle}`;

  if (loading && (searchItems.length === 0 || fromContext !== searchContext)) {
      return (
          <Dimmer active={true} inverted className="page-loader">
              <Loader active>{loaderText}</Loader>
          </Dimmer>
      );
  }

 /*
  if (!_.isArray(searchItems) || searchItems.length === 0) {
      return (
          <>
              <Header textAlign="center">
                  Результаты поиска '{search}' {contextTitle}
              </Header>
              <Container>
                <p>
                  { !token && (fromContext === SEARCH_CONTEXT_TASKS || fromContext === SEARCH_CONTEXT_ORDERS)
                      ? `Для поиска ${contextTitle}, пожалуйста, авторизуйтесь`
                      : 'Ничего не найдено'}
                </p>
              </Container>
          </>
      );
  }*/

  return (
      <InfiniteView
          dataLength={searchItems.length}
          nextFetch={loadMore}
          hasMore={hasMore}
          dataList={(
              <>
                  { fromContext === SEARCH_CONTEXT_ORDERS ? <OrdersList orders={searchItems} title={title} {...props}/> : '' }
                  { fromContext === SEARCH_CONTEXT_TASKS ? <TasksList tasks={searchItems} title={title} {...props}/> : '' }
                  { fromContext === SEARCH_CONTEXT_PRODUCTS ?  <ProductsList products={searchItems} title={title} {...props}/> : '' }
              </>
          )}
          mustAuth={(fromContext === SEARCH_CONTEXT_TASKS || fromContext === SEARCH_CONTEXT_ORDERS)}
          isAuth={!!token}
          isLoading={false}
          loaderText={loaderText}
          zeroText="Ничего не найдено"
      />
  );
}



