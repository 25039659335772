import React, { Component } from 'react';
import { Form, Radio, Label } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './styles.css';
import {
  SHIPPING_METHOD_OWN,
  SHIPPING_METHOD_OZON,
  SHIPPING_METHOD_CDEK,
  SHIPPING_METHOD_DELLIN,
  setShippingMethod, fetchShippingOptions
} from './actions';
import _ from 'lodash';

class ShippingOptions extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.readShippingOptions = this.readShippingOptions.bind(this);
  }

  componentDidMount() {
    this.readShippingOptions();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.shippingOptions[this.props.shippingMethod].isExist)
        this.setMethod( SHIPPING_METHOD_OWN.key);
        /*
     if (this.props.address.fullAddress !== prevProps.fullAddress)
       this.readShippingOptions();
         */
  }

  readShippingOptions(shippingMethod = SHIPPING_METHOD_OWN.key) {
    const { dispatch, address, cart, shippingType } = this.props;
    dispatch(fetchShippingOptions({
      shipping_type: shippingType,
      shipping_method: shippingMethod,
      address_fields: address,
      cart_items: cart,
    }));
  }

  handleChange = (e, { value }) => {
    this.setMethod( value );
  };

  setMethod = (method) => {
      const { dispatch } = this.props;
      dispatch(setShippingMethod( method ));
      this.readShippingOptions( method );
  };

  getMinimalPrice() {
    return  _.min([
        this.props.shippingOptions.own.shippingCost,
        this.props.shippingOptions.dellin.shippingCost,
        this.props.shippingOptions.cdek.shippingCost,
        this.props.shippingOptions.ozon.shippingCost
      ]);
  }

  isMinimalPrice = (method) => this.getMinimalPrice() === this.props.shippingOptions[method].shippingCost;

  render() {
    return (
      <Form>
        { this.props.shippingOptions.own.isExist && this.props.shippingOptions.own.isActual ?
        <Form.Field>
          <Radio
            name="radioGroup"
            label={SHIPPING_METHOD_OWN.name}
            value={SHIPPING_METHOD_OWN.key}
            checked={this.props.shippingMethod === SHIPPING_METHOD_OWN.key}
            onChange={this.handleChange}
            color='blue'
          />
          <Label as='a' className="label-shipping-option"
                 color={this.isMinimalPrice(SHIPPING_METHOD_OWN.key)? "blue" : "grey"}>
            {this.props.shippingOptions.own.shippingCostISO + ' ' +
              this.props.shippingOptions.own.shippingDays}
          </Label>
        </Form.Field>
        : '' }
        { this.props.shippingOptions.dellin.isExist && this.props.shippingOptions.dellin.isActual ?
          <Form.Field>
            <Radio
              name="radioGroup"
              label={SHIPPING_METHOD_DELLIN.name}
              value={SHIPPING_METHOD_DELLIN.key}
              checked={this.props.shippingMethod === SHIPPING_METHOD_DELLIN.key}
              onChange={this.handleChange}
              color='blue'
            />
            <Label as='a' className="label-shipping-option"
                   color={this.isMinimalPrice(SHIPPING_METHOD_DELLIN.key)? "blue" : "grey"}>
              {this.props.shippingOptions.dellin.shippingCostISO + ' ' +
                this.props.shippingOptions.dellin.shippingDays}
            </Label>
          </Form.Field>
          : '' }
        { this.props.shippingOptions.cdek.isExist && this.props.shippingOptions.cdek.isActual ?
          <Form.Field>
            <Radio
              name="radioGroup"
              label={SHIPPING_METHOD_CDEK.name}
              value={SHIPPING_METHOD_CDEK.key}
              checked={this.props.shippingMethod === SHIPPING_METHOD_CDEK.key}
              onChange={this.handleChange}
              color='blue'
            />
            <Label as='a' className="label-shipping-option"
                   color={this.isMinimalPrice(SHIPPING_METHOD_CDEK.key)? "blue" : "grey"}>
              {this.props.shippingOptions.cdek.shippingCostISO + ' ' +
                this.props.shippingOptions.cdek.shippingDays}
            </Label>
          </Form.Field>
          : '' }
        { this.props.shippingOptions.ozon.isExist && this.props.shippingOptions.ozon.isActual ?
          <Form.Field>
            <Radio
              name="radioGroup"
              label={SHIPPING_METHOD_OZON.name}
              value={SHIPPING_METHOD_OZON.key}
              checked={this.props.shippingMethod === SHIPPING_METHOD_OZON.key}
              onChange={this.handleChange}
              color='blue'
            />
            <Label as='a' className="label-shipping-option"
                   color={this.isMinimalPrice(SHIPPING_METHOD_OZON.key)? "blue" : "grey"}>
              {this.props.shippingOptions.ozon.shippingCostISO + ' ' +
                this.props.shippingOptions.ozon.shippingDays}
            </Label>
          </Form.Field>
          : '' }
      </Form>
    );
  }
}

ShippingOptions.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators({
      setDeliveryMethod: setShippingMethod,
      fetchShippingOptions,
    }, dispatch),
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingOptions);
