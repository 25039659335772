import React, {useState} from 'react'
import {Button, Header, Modal, ModalActions} from 'semantic-ui-react'
import Calendar from 'react-calendar';
import './styles.css';
import {isUndefined} from "lodash";

const formatDateToString = (onDate) => {
    function join(date, options, separator) {
        function format(option) {
            let formatter = new Intl.DateTimeFormat('en', option);
            return formatter.format(date);
        }
        return options.map(format).join(separator);
    }

    let options = [{day: '2-digit'}, {month: '2-digit'}, {year: 'numeric'}];
    return join(onDate, options, '.');
}
export default function ModalDateSelection (props) {
    const {open, handleOpen, handleClose, callback, setMinDate, setMaxDate, header, selectedDate} = props;
    const [stateDate, setStateDate] = useState(selectedDate);

    const handleSave = () => {
        const date = formatDateToString(stateDate);
        callback(date);
        handleClose();
    };

return(
    <Modal
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        className="modal-date-selection"
    >
        <Header content={header}/>
        <Modal.Content>
            <div>
                <Calendar maxDate={setMaxDate ?? new Date(9999, 11,31)}
                          minDate={setMinDate ?? new Date()}
                          onClickDay={(value, event)=> setStateDate(value)}/>
            </div>
        </Modal.Content>
        <ModalActions>
            <Button negative onClick={handleClose}>Отмена</Button>
            <Button disabled={isUndefined(stateDate)} positive onClick={handleSave}>Подтвердить</Button>
        </ModalActions>
    </Modal>
)
}