import 'whatwg-fetch';
import config from '../../config/config';

export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD';
export const REQUEST_SHIPPING_OPTIONS = 'REQUEST_SHIPPING_OPTIONS';
export const RECEIVE_SHIPPING_OPTIONS = 'RECEIVE_SHIPPING_OPTIONS';

export const SHIPPING_METHOD_OWN = { key: 'own', name: 'Способом продавца' };
export const SHIPPING_METHOD_OZON = { key: 'ozon', name: 'ОЗОН' };
export const SHIPPING_METHOD_CDEK = { key: 'cdek', name: 'СДЭК' };
export const SHIPPING_METHOD_DELLIN = { key: 'dellin', name: 'Деловые Линии' };

export const SET_SHIPPING_TYPE = 'SET_SHIPPING_TYPE';
export const SHIPPING_TYPE_LOCAL_PICKUP = 'SHIPPING_TYPE_LOCAL_PICKUP';
export const SHIPPING_TYPE_DELIVERING = 'SHIPPING_TYPE_DELIVERING';

export const setShippingType = (deliveryType) => ({
  type: SET_SHIPPING_TYPE,
  deliveryType,
});

export const requestShippingOptions = () => ({
  type: REQUEST_SHIPPING_OPTIONS,
});

export const setShippingMethod = (deliveryMethod) => ({
  type: SET_SHIPPING_METHOD,
  deliveryMethod,
});

export const receiveShippingOptions = (shippingOptions, shippingParams) => ({
  type: RECEIVE_SHIPPING_OPTIONS,
  shippingOptions,
  shippingParams,
});

export const fetchShippingOptions = (params) => (dispatch) => {
  dispatch(requestShippingOptions());
  const paramsJson = JSON.stringify(params);
  const url = config.API_SHIPPING_OPTIONS_URL;

  return fetch(
    url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: paramsJson,
    },
  )
    .then((response) => response.json())
    .then((json) => dispatch(receiveShippingOptions(json, params)))
    .catch(() => {
      dispatch(receiveShippingOptions([], params));
    });
};
