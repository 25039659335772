import { Link } from 'react-router-dom';
import React from 'react';
import {
  Card, Button, Header, Grid, Icon, Divider,
} from 'semantic-ui-react';
import config from '../config/config';
import CircularImage from './CircularImage';
import {toastr} from "react-redux-toastr";
import {addProduct} from "../views/Cart/actions";
import {useSelector} from "react-redux";
import {getCart} from "../views/Cart/reducer";

export default function ProductCard(props) {

  const cart =  useSelector( (state) => getCart(state.cart));

  const {
    categories: propCat, id, src, categoryId, name, shortDescription, price, has_options, virtual,
      dispatch, userData, userLocation, mixpanel
  } = props;

  const product_in_cart = !!cart.find((obj) => obj.id === id,);
  const categories = propCat.map((category) => category.name);

  function addItem() {
    dispatch(
        addProduct(id,
            name,
            price,
            src,
            virtual,
            null,
            null,
        ),
    );
    toastr.success('Добавление в корзину', name + ' добавлен в корзину');
    if (mixpanel)
      mixpanel.track('Add To Cart', { ...userLocation, ...userData, id, product_name: name, price, src, virtual, });
  }

  return (
        <Card centered color="blue" raised>
          <Card.Content>
            <Grid>
              <Grid.Row centered key={id}>
                <Grid.Column width={4}>
                  <Link to={'/product/' + id}>
                    <CircularImage src={src} circular={false}/>
                  </Link>
                </Grid.Column>
                <Grid.Column width={12} textAlign="center">
                  <Card.Header as="h3" className="break-words">{name}</Card.Header>
                  <Link to={'/products/' + categoryId}>
                    <Card.Meta>{categories.join(', ')}</Card.Meta>
                  </Link>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider/>
            <div size="tiny" dangerouslySetInnerHTML={{__html: shortDescription}}/>
          </Card.Content>
          <Card.Content extra>
            { price ?
                <Header as="h3" color="orange" className="mini-product-card-price">
                  <div dangerouslySetInnerHTML={{__html: price + ' ' + config.CURRENCY}}/>
                </Header>
                : null
            }
            { has_options ?
                  <Link to={'/product/' + id}>
                      <Button primary compact>Выбрать опции</Button>
                  </Link> :
                  <div>
                      { product_in_cart ?
                          <Link to="/cart">
                            <Button color="green" compact className="product-card-button-goto-cart">
                              В корзине, перейти &nbsp;
                              <Icon name="cart"/>
                            </Button>
                          </Link> :
                          <Button primary compact onClick={addItem}>
                              В корзину &nbsp;
                              <Icon name="cart"/>
                          </Button>
                      }
                      <Link to={'/product/' + id}>
                          <Button secondary compact>Подробнее</Button>
                      </Link>
                  </div>
            }
          </Card.Content>
        </Card>
    );
}
