import React from 'react';
import { Button } from 'semantic-ui-react';
import {useSelector} from 'react-redux';

import { getShippingMethod, getShippingType } from './ShippingCard/reducer';

import {
    ADDRESS_ADDINFO,
    ADDRESS_ADDRESS, ADDRESS_CITY,
    ADDRESS_COMPANY, ADDRESS_COMPANY_ADDR, ADDRESS_COMPANY_JOB_TITLE, ADDRESS_COMPANY_SCOPE, ADDRESS_COUNTRY,
    ADDRESS_EMAIL, ADDRESS_FIRSTNAME,
    ADDRESS_GEOLAT,
    ADDRESS_GEOLON, ADDRESS_LASTNAME, ADDRESS_PHONE, ADDRESS_PHONE_COUNTRY, ADDRESS_POSTCODE,
    ADDRESS_STATE,
} from './AddressCard/actions';
import { getAddressFields} from './AddressCard/reducer';
import {getPaymentMethod} from "../views/Checkout_2_StepPayment/reducer";
import {getCartItems, hasOnlyVirtuals} from "../views/Cart/reducer";
import {putOrder} from "../views/Orders/actions";

export default function ConfirmOrderButton(props) {

    const {dispatch, token, userData, userLocation, mixpanel, navigate} = props;

    const cart_items = useSelector((state) => getCartItems(state.cart));
    const only_virtuals = useSelector((state) => hasOnlyVirtuals(state.cart));

    const shipping_type = only_virtuals? '' : useSelector((state) => getShippingType(state.shipping));
    const shipping_method = only_virtuals ? '' : useSelector((state) => getShippingMethod(state.shipping));
    const address_fields = useSelector((state) => getAddressFields(state.address));
    const payment_option = useSelector((state) => getPaymentMethod(state.payment));


    const orderData = {
        user_location: JSON.stringify({
            userData: {id: userData.id, login: userData.login},
            userLocation,
        }),

        company_meta: JSON.stringify(address_fields[ADDRESS_COMPANY_ADDR] ?? ''),

        shipping_type,
        shipping_method,
        payment_option,

        create_account: '0',

        cart_items: JSON.stringify(cart_items),

        billing: JSON.stringify({
            first_name: address_fields[ADDRESS_FIRSTNAME],
            last_name: address_fields[ADDRESS_LASTNAME],
            company: address_fields[ADDRESS_COMPANY] ?? '',
            company_job_title: address_fields[ADDRESS_COMPANY_JOB_TITLE] ?? '',
            company_scope: address_fields[ADDRESS_COMPANY_SCOPE] ?? '',
            address_1: address_fields[ADDRESS_ADDRESS] ?? '',
            address_2: address_fields[ADDRESS_ADDINFO] ?? '',
            country: address_fields[ADDRESS_COUNTRY],
            state: address_fields[ADDRESS_STATE],
            city: address_fields[ADDRESS_CITY],
            postcode: address_fields[ADDRESS_POSTCODE],
            phone: address_fields[ADDRESS_PHONE],
            phone_country: address_fields[ADDRESS_PHONE_COUNTRY],
            email: address_fields[ADDRESS_EMAIL] ?? '',
            address_geo_lon: address_fields[ADDRESS_GEOLON] ?? '',
            address_geo_lat: address_fields[ADDRESS_GEOLAT] ?? '',
        }),
    };

    function handleClick() {

        console.log('put order', orderData);

        dispatch(putOrder(token, orderData, navigate, props.pathToRoute));

        if (mixpanel)
            mixpanel.track('Checkout Place Order', {
                ...userLocation, ...userData,
                payment_option, ...address_fields,
                cart_items
            });
    }

    return (
        <Button
            color="green"
            className= {`${props.addClassName} button-confirm`}
            onClick={handleClick}
            disabled={props.disabled ?? false}
        >
            {props.buttonText}
        </Button>
    );
}



