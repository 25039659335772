import { combineReducers } from 'redux';
import {
    REQUEST_FAQ, RECEIVE_FAQ, RECEIVE_1PAGE_FAQ, FAQ_PAGE_COUNT,
} from './actions';
import {mergeObjectArrays} from "../../configureStore";

const items = (state = [], action = 0) => {
    switch (action.type) {
        case REQUEST_FAQ:
            return state;
        case RECEIVE_FAQ:
            return mergeObjectArrays(action.faq, state);
        case RECEIVE_1PAGE_FAQ:
            return action.faq;
        default:
            return state;
    }
};

const hasMore = (state = false, action) => {
    switch (action.type) {
        case REQUEST_FAQ:
            return true;
        case RECEIVE_FAQ:
        case RECEIVE_1PAGE_FAQ:
            return action.faq.length >= FAQ_PAGE_COUNT;
        default:
            return state;
    }
};

const isFetching = (state = {fetching: false, page: 1}, action) => {
    switch (action.type) {
        case REQUEST_FAQ:
            return {fetching: true, page: action.page};
        case RECEIVE_FAQ:
        case RECEIVE_1PAGE_FAQ:
            return {...state, fetching: false};
        default:
            return state;
    }
};

export const getFAQ = (state) => {
    return state.items;
};

export const getFAQFetching = (state) => state.isFetching.fetching && state.isFetching.page === 1;
export const getFAQHasMore = (state) => state.hasMore;

export default combineReducers({
    items,
    isFetching,
    hasMore,
});