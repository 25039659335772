
import React, {useEffect, useState} from 'react';
import {Dropdown} from "semantic-ui-react";
import InputMask from "react-input-mask";
import {ADDRESS_STATE} from "./actions";

const PhoneCountryMask = (props) => {

    const { countries, loading, country, extraClass, phone, handleChangeField, nameCountry, namePhone, fluid } = props;

    const [state, setState] = useState({
        isoCountry: country ?? "RU",
        phoneMask: "+7 (r##) ###-##-##",
        phone: phone,
    });

    useEffect(() => {
        // when component mounts
        const phone_element = document.getElementById("masked_phone");

        if (phone_element && phone_element.value.length && phone_element.value !== state.phone) {
            setState({...state, phone: phone_element.value});
            handleChangeField(namePhone, phone_element.value);
        }
    }, [state.phoneMask]);

    function onChangeCountry(e, { value }) {
        const rows = countries.filter((row) => row.value === value);
        setState( { ...state, isoCountry: value, phoneMask: rows[0].phone_mask} );
        handleChangeField(nameCountry, value);
    }

    function onChangePhone(e) {
        setState( { ...state, phone: e.target.value} );
        handleChangeField(namePhone, e.target.value);
    }

    return (
        <div className={`ui input phone-country-mask ${ extraClass + (fluid ? ' fluid' : '') }`}>
            <Dropdown
                id="dropdown_country"
                name={ nameCountry }
                options={ countries }
                loading = { loading }
                value={ state.isoCountry }
                onChange={ onChangeCountry }
                search
                selection
                scrolling
                fluid
                noResultsMessage="Ничего не найдено"
            />
            <InputMask
                mask={ state.phoneMask }
                maskChar="_"
                placeholder="Мобильный телефон"
                id="masked_phone"
                name= { namePhone }
                value={ phone }
                onChange={ onChangePhone }
                type="tel"
                formatChars={{
                    '9': '',
                    '#': '[0-9]',
                    'r': '[0|1|2|3|4|5|8|9]',
                    'k': '[6|7]'
                }}
            />
        </div>
    );
};

export default PhoneCountryMask;