import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import {
    Card, Divider, Header, Icon, Loader, List, Button, Checkbox, Rating,
    Segment, Message, Modal, Dimmer, Image, Input, Label, Accordion, TextArea, Dropdown, Form, Grid,
    CommentText, CommentMetadata, CommentGroup, CommentContent, CommentAvatar, CommentActions, CommentAction,
    CommentAuthor, Comment
} from 'semantic-ui-react';

import _, {isArray, isUndefined} from 'lodash';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ImageUploading from 'react-images-uploading';
import SignaturePad from 'react-signature-canvas';

import {getTasks, getTasksFetching, getTaskUpdating} from '../Tasks/reducer';
import { fetchTasks, updateTaskDecision, updateTaskStep, uploadFile } from '../Tasks/actions';
import TaskCard, {get_formatted_price} from '../../components/TaskCard/TaskCard';

import './styles.css';
import { useParams } from 'react-router';
import { toastr } from 'react-redux-toastr';
import GeoLocation from '../../components/GeoLocation/GeoLocation';
import { getProducts, getProductsFetching } from '../Products/reducer';
import {fetchAllProducts, PRODUCTS_PAGE_COUNT} from '../Products/actions';
import {SERVICE_TASK_LEVEL_WORKING, SERVICE_TASK_STATE_IN_PROGRESS} from "../../config/config";

export default function Task (props) {

  const {dispatch, token, userLocation, userData, mixpanel} = props;

  const {taskId: taskID} = useParams();

  const isUpdating = useSelector((state) => getTaskUpdating(state.tasks));
  const isFetching = useSelector((state) => getTasksFetching(state.tasks));
  const tasks = useSelector((state) => getTasks(state.tasks));
  const task = tasks.find( (obj) => obj.id === taskID );
  const products = useSelector((state) => getProducts(state.products));
  const recommendedProducts = _.map(products, (item, index) => ({
    key: index,
    text: item.name,
    value: String(item.id),
  }));
  const loadingProducts = useSelector((state) => getProductsFetching(state.products));

  const [statePhoto, setStatePhoto] = useState({
      hasOpen: false,
      hasLoaded: false,
      dataUri: '',
      step: '',
      tag: '',
      operation: '',
      confirmed_date:'',
      mode: FACING_MODES.ENVIRONMENT,
      imageSource: 'camera',
  });
  const [images, setImages] = useState([]);
  const sigCanvas = useRef({});

  const [taskDecision, setTaskDecision] = useState(
        {message: '', recommendedList: []});

  const [stateStepsQty, setStateStepQty] = useState([]);

  useEffect(() => {
        if (mixpanel)
            mixpanel.track('Task Card View', { ...userLocation, ...userData, task_id: taskID });

        dispatch(fetchTasks(token, {id: taskID}));
        dispatch(fetchAllProducts({page: 1, per_page: PRODUCTS_PAGE_COUNT}));

        window.scrollTo(0, 0);
    }, [taskID]);

  useEffect(() => {
      if (task && task.data && task.data.decision) {
          const {data: {decision: {message, recommended_list}}} = task;
          setTaskDecision({message: message ?? '', recommendedList: recommended_list ?? []})
      }
      const stepsQty = task && task.data && isArray(task.data.steps) ?
          task.data.steps.map(
              (element, index) => ({
                  'quantity': element.quantity ?? element.default_quantity ?? '1',
                  'disabled': (element.is_qty_changeable === 'no' || element.done === 'yes'),
                  'label': element.measurement_desc && element.measurement_desc.label
                      ? element.measurement_desc.label
                      : 'Количество работы',
                  'unit': element.measurement_desc && element.measurement_desc.unit
                      ? element.measurement_desc.unit
                      : 'ед.изм.',
                  'min_quantity': element.default_quantity ?? 1,
                  'max_quantity': 999,
                  'error': false,
                  'error_text': '',
              })) : [];
      setStateStepQty(stepsQty);
  }, [task]);

  const isWorking = () => ( !isUndefined(task) && task.task_status === SERVICE_TASK_STATE_IN_PROGRESS );

  function triggerFacingMode () {
    if (statePhoto.mode === FACING_MODES.ENVIRONMENT)
      setStatePhoto({...statePhoto, mode: FACING_MODES.USER});
    else
      setStatePhoto({...statePhoto, mode: FACING_MODES.ENVIRONMENT});
  }

  function addDocument (image)  {
    if (statePhoto.step === '' || statePhoto.operation === '') {
      console.warn('Пустые значения для отправки файла', statePhoto);
      return;
    }
    const random = (Math.random() + 1).toString(10).substring(6);
    const formData = {
      taskID,
      step: statePhoto.step,
      operation: statePhoto.operation,
      fileName: (String(taskID) + '-' + String(statePhoto.step) + '-' + random),
      comment: statePhoto.tag,
      source: statePhoto.imageSource,
    };
    if (mixpanel)
      mixpanel.track('Task Card Add Document', { ...userLocation, ...userData, ...formData, source: null });
    dispatch(uploadFile(token, { ...formData, ...userLocation }, image));
    setStatePhoto({...statePhoto, imageSource: '', operation: '', hasOpen: false, hasLoaded: false, dataUri: ''});
    setImages([]);
  }

  function updateDocument (step, operation, image, comment)  {
    if (step === '' || operation === '') {
      console.warn('Пустые значения для обновления файла', statePhoto);
      return;
    }
    if (mixpanel)
      mixpanel.track('Task Card Update Document', { ...userLocation, ...userData,  taskID, step, operation, comment });
    dispatch(uploadFile(token, { taskID, step, operation, comment, ...userLocation }, image));
    setStatePhoto({...statePhoto, operation: '', tag: ''});
  }

  function onStepToggle(e, data, step) {
      if (stateStepsQty[step].error) {
          toastr.error(
              `Изменения не допускаются: ${_.lowerCase(stateStepsQty[step].label)} ${stateStepsQty[step].error_text}`);
          return;
      }
    setStatePhoto({...statePhoto, step});
    const formData = {
        taskID,
        step,
        operation: 'complete',
        done: (data.checked ? 'yes' : 'no'),
        quantity: stateStepsQty[step].quantity,
    };
    if (mixpanel)
      mixpanel.track('Task Card Update Step', { ...userLocation, ...userData, ...formData});
    dispatch(updateTaskStep(token, { ...formData, ...userLocation }));
  }

  function saveConclusion() {
    if (mixpanel)
      mixpanel.track('Task Card Update Decision', { ...userLocation, ...userData,  taskID, ...taskDecision});
    dispatch(updateTaskDecision(token, taskID, taskDecision));
  }

  function onChangeQuantity(e, data, step) {
      const isNum = /^\d+$/.test(data.value);
      const minNum = stateStepsQty[step].min_quantity;
      const maxNum = stateStepsQty[step].max_quantity;
      let newStepsQty = structuredClone(stateStepsQty);
      newStepsQty[step].quantity = data.value;
      const error_text= isNum
          ? (Number(data.value) >= minNum && Number(data.value) <= maxNum
              ? ''
              : 'должно быть в диапазоне между '+ minNum + ' и ' + maxNum)
          : 'должно быть целым положительным';
      newStepsQty[step].error_text = error_text;
      newStepsQty[step].error = Boolean(error_text.length);
      setStateStepQty(newStepsQty);
  }
  const toastNotWorking = () => toastr.warning('Любые изменения запрещены, состояние задачи должно быть в работе!')

  // ----- UI rendering ---------------------------------------------------------------------------------------------

  if (isFetching) {
        return (
            <Dimmer active inverted>
                <Loader>Пожалуйста, подождите ...</Loader>
            </Dimmer>
        );
  }

  if (_.isUndefined(task)) {
      return <div>Задача {taskID} не найдена</div>;
  }

  const listFiles = (stepIndex) =>
    task.data.steps[stepIndex].files.map((file, index) => (
     <List.Item key={index}>
       <Grid className="task-card-step-content">
         <Grid.Row>
           <Grid.Column width={6} className="task-card-step-doc-image">
             { file.url.split('.').pop() !== 'pdf'
                 ? <Image size="tiny" verticalAlign="middle" src={ file.url } href={ file.url } target="_blank"/>
                 : <Icon name="file pdf outline" size="huge" link
                         onClick={ () => window.open(file.url, '_blank') }/>
             }
           </Grid.Column>
           <Grid.Column width={10} className="task-card-step-doc-content">
              <List.Content>
                  <List.Header as='a'>{ file.comment }</List.Header>
                  <List.Description>
                    <div className={ 'task-file-state-text' + (file.state === 'remove' ? '-deleted' : '') }>
                      Документ { (file.state === 'add' ? 'загружен' : file.state === 'modify' ? 'изменен' : 'удален') + ' ' + file.timestamp }
                    </div>
                  </List.Description>
              </List.Content>
           </Grid.Column>
          </Grid.Row>
          <Grid.Row className="task-card-step-row-buttons">
            <Grid.Column width={6} className="task-card-step-doc-space"/>
            <Grid.Column width={10} className="task-card-step-doc-buttons">
            <div className="task-file-buttons">
         <Button icon color="blue" onClick={ () =>
             isWorking()
                 ? setStatePhoto({
                   imageSource: 'preview',
                   operation: 'modify',
                   tag: file.comment,
                   step: stepIndex,
                   hasOpen: true,
                   hasLoaded: true,
                   dataUri: file.url}
                 )
                 : toastNotWorking()
         }>
            <Icon name="pencil" />
         </Button>
         <Button icon onClick={ () =>
             isWorking()
                 ? updateDocument(stepIndex, 'remove', file.url, file.comment)
                 : toastNotWorking()
         }>
            <Icon name="trash" />
         </Button>
         <Button icon onClick={ () =>
             isWorking()
                 ? updateDocument(stepIndex, 'undo', file.url, file.comment)
                 : toastNotWorking()
         }>
            <Icon name="undo alternate" />
         </Button>
            </div>
            </Grid.Column>
          </Grid.Row>
       </Grid>
     </List.Item>
  ));

  const showDocuments = (index, element) => (
    element.proof_photo === 'yes' || element.proof_sign === 'yes' || element.proof_conclusion === 'yes' ? (
    <Segment className="task-step-document-placeholder">
      <Header as="h4">
        <div className="task-document-title">
          { task.data.steps[index].files ? 'Документы прикреплены' : 'Зафиксируйте результаты' }
        </div>
        <div className="task-document-icons">
          { element.proof_photo === 'yes' ?
                <a>
                  <Icon className="task-document-icon" name="photo" />
                  <>фотографии c места работ</>
                </a> : ''}
          { element.proof_conclusion === 'yes' ?
                <a>
                  <Icon className="task-document-icon" name="file pdf outline" />
                  <>PDF текстовые заключения</>
                </a> : '' }
          { element.proof_sign === 'yes' ?
                <a>
                  <Icon className="task-document-icon" name="signup" />
                  <>онлайн подпись клиента</>
                </a> : '' }
        </div>
      </Header>
      { task.data.steps[index].files ?
        <div>
          <Divider/>
          <List>
            { listFiles(index) }
          </List>
        </div>
        : '' }
      <Divider/>
      <Button.Group fluid>
        { element.proof_photo === 'yes' || element.proof_conclusion === 'yes' ?
          <>
            <Button
              compact
              icon
              onClick={() =>
                isWorking()
                  ? setStatePhoto({...statePhoto, imageSource: 'file', operation: 'add', step: index, hasOpen: true, hasLoaded: true, dataUri: ''})
                    : toastNotWorking()
              }
            ><Icon name="folder open" /> Файл</Button>
            <Button
              compact
              positive
              icon
              onClick={() =>
                  isWorking()
                      ? setStatePhoto({...statePhoto, imageSource: 'camera', operation: 'add', step: index, hasOpen: true, hasLoaded: false, dataUri: ''})
                      : toastNotWorking()
              }
            ><Icon name="camera" /> Фото</Button>
          </> : '' }
        { element.proof_sign === 'yes' ?
          <Button
            compact
            icon
            onClick={() =>
                isWorking()
                    ? setStatePhoto({...statePhoto, imageSource: 'sign', tag: 'подпись клиента', operation: 'add', step: index, hasOpen: true, hasLoaded: true, dataUri: ''})
                    : toastNotWorking()
            }
          ><Icon name="signup" /> Подпись
          </Button> : '' }
      </Button.Group>
    </Segment>) : ''
  );

  const panel_task = (step) => (
      <Grid>
        <Grid.Column width={12} className="task-card-step-description">
          {step.short_description}
        </Grid.Column>
        <Grid.Column width={3} className="task-card-step-amount">
          {get_formatted_price(step.cost ?? 0, 'без оплаты')}
        </Grid.Column>
      </Grid>
  )

    const reviews = (_.isUndefined(task) || _.isUndefined(task.data) || _.isUndefined(task.data.reviews) || _.size(stateStepsQty) === 0)
        ? []
        : task.data.reviews.map((element, index) => ({
            key: `review-${index}`,
            title: {
                content: element.done === 'yes'
                    ? ''
                    : (element.mandatory === 'yes'
                        ? ''
                        : '')
            },
            content: {
                content: (
                    <Comment>
                        <Comment.Avatar src={element.reviewer_avatar} />
                        <Comment.Content>
                            <CommentAuthor>{element.reviewer}</CommentAuthor>
                            <Comment.Metadata>
                                <div>{element.timestamp}</div>
                                {element.source === "admin" ? (
                                        <div>От проверяющего специалиста</div>
                                    ) :
                                    <div>От клиента</div>
                                }
                            </Comment.Metadata>
                            <Comment.Text>
                                <div>Оценка: <Rating icon='star' rating={element.rating} maxRating={5} disabled/></div>
                                <div><strong>Плюсы:</strong> {element.pros}</div>
                                <div><strong>Минусы:</strong> {element.cons}</div>
                                <div><strong>Комментарий:</strong> {element.comment}</div>
                            </Comment.Text>
                        </Comment.Content>
                    </Comment>
                ),
            },
        }));

  const panels = (_.isUndefined(task)
            || _.isUndefined(task.data)) || _.isUndefined(task.data.steps) || _.size(stateStepsQty) === 0
      ? []
      : task.data.steps.map((element, index) => ({
    key: `panel-${index}`,
    title: {
      content:
        element.done === 'yes'
            ? <Label
                icon="check"
                className="task-step-title"
                color="green"
                content={panel_task(element)}
            />
            : (element.mandatory === 'yes'
                ? <Label color="red" className="task-step-title" content={panel_task(element)} />
                : <Label className="task-step-title" content={panel_task(element)}/>)
    },
    content: {
      content: (
        <Message info>
          <Message.Content>
              <Grid className="task-card-step-header">
                  <Grid.Row className="task-card-step-header-row-first">
                      <Grid.Column width={6}>
                          <a
                              className={"task-user-manual-link " + element.user_manual_type}
                              target="_blank"
                              href={element.user_manual}
                              type="application/pdf"
                              rel="noreferrer"
                          >
                            Инструкция выполнения
                          </a>
                      </Grid.Column>
                      <Grid.Column width={10}>
                          <Checkbox
                              className="task-done-toggle"
                              toggle checked={element.done === 'yes'}
                              label="Выполнено"
                              onChange={(e, data) =>
                                  isWorking()
                                      ? onStepToggle(e, data, index)
                                      : toastNotWorking()
                              }
                          />
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="task-card-step-header-row-first">
                      <Grid.Column width={16}>
                          <Input
                              labelPosition="right"
                              type="number"
                              className="task-card-step-quantity"
                              fluid
                              value={stateStepsQty[index].quantity}
                              disabled={stateStepsQty[index].disabled}
                              onChange={(e, data) =>
                                  isWorking() ? onChangeQuantity(e, data, index) : toastNotWorking()}
                              error={stateStepsQty[index].error}
                              id={'input-quantity--' + index}
                          >
                              <Label basic>{stateStepsQty[index].label}</Label>
                              <input className="input-field"/>
                              <Label>{stateStepsQty[index].unit}</Label>
                          </Input>
                          {
                              stateStepsQty[index].error
                                  ? <span className="task-card-field-error">{stateStepsQty[index].error_text}</span>
                                  : ''
                          }
                      </Grid.Column>
                  </Grid.Row>
              </Grid>
              {showDocuments(index, element)}
          </Message.Content>
        </Message>
      ),
    },
  }));

  const onUploadPhoto = (imageList, addUpdateIndex) => {
    // data for submit
    imageList.length > 0 ?
        setStatePhoto({...statePhoto, dataUri: imageList[0].data_url})
        : setStatePhoto({...statePhoto, dataUri: '' }) ;

    setImages(imageList);
  };
  const clearSigCanvas = () => sigCanvas.current.clear();
  const saveSigCanvas = () => {
    setStatePhoto({...statePhoto, dataUri: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")});
  };
  const showImageModal = () => (
    <Modal
      closeIcon
      dimmer
      open={ statePhoto.hasOpen }
      centered
      size="fullscreen"
      closeOnDimmerClick={true}
      className="make-photo"
      onOpen={ () => setStatePhoto({...statePhoto, hasOpen: true, hasLoaded: false}) }
      onClose={ () => setStatePhoto({...statePhoto, operation: '', hasOpen: false, hasLoaded: false, dataUri: ''}) }
    >
      <Header icon="photo" content="Прикрепите результат" />
      <GeoLocation />
      <Modal.Content>
        {statePhoto.imageSource === 'preview' || ( statePhoto.imageSource === 'camera' && statePhoto.dataUri !== '') ?
          <div className="image-preview image-preview-fullscreen">
            { statePhoto.dataUri.split('.').pop() !== 'pdf' ?
              <Image src={statePhoto.dataUri} />
              : <Icon name="file pdf outline" size="massive" />
            }
          </div> : '' }

        {statePhoto.imageSource === 'camera' && statePhoto.dataUri === '' ?
            <Camera
              onTakePhoto={ (dataUri) => setStatePhoto({...statePhoto, dataUri}) }
              isFullscreen={false}
              isImageMirror={false}
              imageCompression={0.92}
              idealFacingMode={statePhoto.mode}
              onCameraStart={ () => setStatePhoto({...statePhoto, hasLoaded: true}) }
              onCameraError={ (error) =>
              {
                toastr.error('Ошибка запуска камеры! ' + String(error));
                setStatePhoto({...statePhoto, hasOpen: false, hasLoaded: false, dataUri: ''});
              }}
            /> : '' }

        {statePhoto.imageSource === 'file' ?
              <div>
                <ImageUploading
                  multiple={false}
                  value={images}
                  onChange={onUploadPhoto}
                  maxNumber={1}
                  dataURLKey="data_url"
                  acceptTypes={['jpg', 'gif', 'png', 'jpeg']}
                  allowNonImageType={true}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    isDragging,
                    dragProps,
                  }) => (
                    <Segment className="upload__image-wrapper">
                      { statePhoto.dataUri === '' ?
                        <Button
                          icon
                          labelPosition="right"
                          color={isDragging ? 'blue'  : 'green'}
                          onClick={onImageUpload}
                          fluid
                          size="massive"
                          basic
                          {...dragProps}
                        >
                          Поместите сюда файл или нажмите для его выбора
                        </Button>
                        : <Icon
                            className="delete-uploaded-image"
                            onClick={onImageRemoveAll}
                            name="trash"
                            color="red"
                            corner="top right"
                            bordered
                        />
                      }
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          {image['data_url'].split(';')[0].split('/')[1] === 'pdf' ?
                            <Icon name="file pdf outline" size="massive" />
                            : <img src={image['data_url']} alt="" width="100%" />
                          }
                        </div>
                      ))}
                    </Segment>
                  )}
                </ImageUploading>
              </div> : '' }

        {statePhoto.imageSource === 'sign' ?
          <div>
            <Icon
              className="delete-uploaded-image"
              onClick={clearSigCanvas}
              name="trash"
              color="red"
              corner="top right"
              bordered
            />
            <SignaturePad
              penColor="blue"
              ref={sigCanvas}
              canvasProps={{className: 'sigCanvas'}}
              onEnd={saveSigCanvas}
            />
          </div> : ''}

        <Dimmer active={ !statePhoto.hasLoaded }>
          <Loader>{ 'Запускаем камеру' }</Loader>
        </Dimmer>
        <Divider hidden className="task-document-modal-divider"/>
        <div className="camera-control-wrapper">
          { statePhoto.imageSource === 'camera' ?
          <Button
            icon
            onClick={triggerFacingMode}
            floated="left"
            disabled={statePhoto.dataUri.length > 0}
          >
            <Icon name={statePhoto.mode ===  FACING_MODES.ENVIRONMENT? 'image' : 'user'} />
          </Button> : ''}
          <Input
            label={{ tag: true, content: 'тег' }}
            labelPosition="right"
            placeholder="Введите описание"
            onChange={ (e) => setStatePhoto({...statePhoto, tag: e.target.value}) }
            defaultValue={statePhoto.tag}
            fluid
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={() =>
            setStatePhoto({...statePhoto, operation: '', hasOpen: false, hasLoaded: false, dataUri: ''})
          }>
          Отмена
        </Button>
        <Button
          positive
          disabled={ statePhoto.dataUri === '' || statePhoto.tag === '' }
          onClick={ () => addDocument(statePhoto.dataUri) }
        >
          Сохранить
        </Button>
      </Modal.Actions>
    </Modal>
  );

  return (
    <div>
      <Dimmer active={ isUpdating === task.id } inverted>
        <Loader className="task-update-loader"/>
      </Dimmer>
      <GeoLocation/>
      <TaskCard
        key={task.id}
        task={task}
        image="no"
        hideButtonSteps
        {...props}
      />

      {task.task_status_level >= SERVICE_TASK_LEVEL_WORKING ?
          <>
            <Card centered color="teal" raised>
                <Header as='h3' color="blue" className="task-steps-header">
                    <Icon name='comment' />
                    <Header.Content>
                        История сообщений
                    </Header.Content>
                </Header>
                <Divider fitted />
                <Card.Content style={{border: "none"}}>
                    {reviews && reviews.length > 0 && (
                    <Comment.Group>
                        {reviews.map((review, index) => (
                            <Comment key={review.key}>
                                <Comment.Content>
                                    <Comment.Text>{review.content.content}</Comment.Text>
                                </Comment.Content>
                                {index !== reviews.length - 1 && <Divider fitted />}
                            </Comment>
                        ))}
                    </Comment.Group>
                    )}
                </Card.Content>
            </Card>

            <Card centered color="teal" raised>
              <Header as='h3' color="blue" className="task-steps-header">
                <Icon name='ordered list' />
                <Header.Content>
                  Порядок оказания услуги
                  <Header.Subheader>работы по стандарту</Header.Subheader>
                </Header.Content>
              </Header>
              <Divider fitted />
              { statePhoto.step === ''
                  ? <Accordion panels={panels} defaultActiveIndex={-1}/>
                  : <Accordion panels={panels} defaultActiveIndex={ Number(statePhoto.step) }/> }
            </Card>

            <Card centered color="teal" raised className="task-conclusion">
              <Header as='h3' color="blue" className="task-conclusion-header">
                <Icon name='thumbs up' />
                <Header.Content>
                  Заключение для клиента
                  <Header.Subheader>рекомендации и пожелания</Header.Subheader>
                </Header.Content>
              </Header>
              <Dropdown
                className="task-recommended-list"
                placeholder="Рекомендуйте ему продукты и услуги"
                noResultsMessage="Ничего не найдено"
                multiple
                search
                selection
                loading={loadingProducts}
                options={recommendedProducts}
                value={taskDecision.recommendedList}
                onChange={(event, data)=>
                    isWorking()
                      ? setTaskDecision({...taskDecision, recommendedList: data.value})
                        : toastNotWorking()
                }
              />
              <Form className="task-conclusion-text">
                <TextArea
                  placeholder="Напишите, пожалуйста, рекомендации и пожелания по итогам всех работ для повышения вашего рейтинга, и они будут направлены клиенту!"
                  value={taskDecision.message}
                  onChange={(event, data)=>
                      isWorking()
                        ? setTaskDecision({...taskDecision, message: data.value})
                        : toastNotWorking()
                 }
                />
              </Form>
              <Button
                className="task-conclusion-save"
                positive
                compact
                disabled={ taskDecision.message === '' }
                onClick={ () => isWorking() ? saveConclusion() : toastNotWorking() }
              >
                Сохранить
              </Button>
            </Card>
            { showImageModal() }
          </>
        : '' }
    </div>
  );
}



