import React, {useEffect, useState} from 'react'
import {
    Button, Checkbox, Header, Modal, ModalActions, Grid, Dropdown, Divider, Segment
} from 'semantic-ui-react'
import './styles.css';
import _ from 'lodash';

export default function ModalFilter (props) {
    const {open, handleOpen, handleClose, callback, filter, orderby, mixpanel} = props;

    const [stateFilter, setStateFilter] = useState([]);
    const [stateOrderBy, setStateOrderBy] = useState([]);
    const valueOrderBy = orderby.map((item, index) => ({
        key: index, value: item.key, text: item.label
    }));

    const listDirections = [
        { key: '0', text: 'вниз', value: 'desc' },
        { key: '1', text: 'вверх', value: 'asc' },
    ];

    useEffect(() => {
        setStateFilter(filter);
        setStateOrderBy(orderby);
        if (mixpanel)
            mixpanel.track('Task Filter Open Modal');
    }, [props]);

    const handleSave = () => {
        callback(stateFilter, stateOrderBy);
        handleClose();
        if (mixpanel)
            mixpanel.track('Task Filter Save');
    };

    const handleCancel = () => {
        handleClose();
        if (mixpanel)
            mixpanel.track('Task Filter Cancel');
    };

    const handleCheckboxChange = (e, props) => {
        const index = props.id.split('--').pop();
        let newFilter = structuredClone(stateFilter);
        newFilter[index].value = Number(props.checked);
        setStateFilter(newFilter);
        if (mixpanel)
            mixpanel.track('Task Filter Changed', {'object': 'filter', 'key': props.id, 'value': props.checked});
    }

    const handleDropdownChangeDirections = (e, props) => {
        const index = props.id.split('--').pop();
        let newOrder = structuredClone(stateOrderBy);
        newOrder[index].direction = props.value;
        setStateOrderBy(newOrder);
        if (mixpanel)
            mixpanel.track('Task OrderBy Changed', {'object': 'order direction', 'key': props.id, 'value': props.value});
    }

    const handleDropdownChangeLables = (e, data) => {
        const index = data.id.split('--').pop();
        let newOrder = structuredClone(stateOrderBy);
        let selectedOrder = newOrder[index];
        let indexSelected = _.findIndex(newOrder, { key: data.value })
        let takedOrder = structuredClone(newOrder[indexSelected]);
        newOrder[indexSelected] = selectedOrder;
        newOrder[index] = takedOrder;
        setStateOrderBy(newOrder);
        if (mixpanel)
            mixpanel.track('Task OrderBy Changed', {'object': 'order field', 'key': props.id, 'value': data.value});
    }

    const listFilterItems = () => {
        let items = [];
        for (let index = 0; index < stateFilter.length; index += 2) {
            items.push(
                        <Grid.Row key={index} className="modal-filter-row" >
                            <Grid.Column className="grid-column-filter" width="8">
                                <Checkbox
                                    className={'modal-filter-checkbox state-' + stateFilter[index]}
                                    id={'filter-state--' + index}
                                    defaultChecked={Boolean(stateFilter[index].value)}
                                    disabled={Boolean(stateFilter[index].disabled)}
                                    label={stateFilter[index].label}
                                    onChange={handleCheckboxChange}
                                />
                            </Grid.Column>
                                <Grid.Column className="grid-column-filter" width="8">
                                    {stateFilter[index + 1]
                                        ? <Checkbox
                                            className={'modal-filter-checkbox-right state-' + stateFilter[index + 1]}
                                            id={'filter-state--' + (index + 1)}
                                            defaultChecked={Boolean(stateFilter[index + 1].value)}
                                            disabled={Boolean(stateFilter[index + 1].disabled)}
                                            label={stateFilter[index + 1].label}
                                            onChange={handleCheckboxChange}
                                        />
                                        : ""
                                    }
                                </Grid.Column>
                        </Grid.Row>
            );
        }
        return items;
    };

    const listOrderByItems = () =>
        stateOrderBy.map((item, index) => (
                <Grid.Row key={index} className="modal-filter-row orderby">
                    <Grid.Column width="2">
                            {index + 1}
                    </Grid.Column>
                    <Grid.Column width="8">
                        <Dropdown
                            id={ 'orderby-state--' + index }
                            className='modal-orderby-dropdown'
                            options={valueOrderBy}
                            placeholder='Выберите параметр'
                            value={stateOrderBy[index].key}
                            onChange={handleDropdownChangeLables}
                        />
                    </Grid.Column>
                    <Grid.Column width="6">
                        <Dropdown
                            id={ 'orderby-sort--' + index }
                            className='modal-direction-dropdown'
                            options={listDirections}
                            onChange={handleDropdownChangeDirections}
                            value={stateOrderBy[index].direction}
                            placeholder="Выберите порядок"
                        />
                    </Grid.Column>
                </Grid.Row>
        ));

    return(
        <Modal
            closeIcon
            dimmer
            centered
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            className="modal-task-filter"
        >
            <Header content="Настройки задач"/>
            <Modal.Content>
                <Header textAlign="left" as="h4">Показывать в состояниях</Header>
                <Segment color="blue">
                    <Grid>
                        {listFilterItems()}
                    </Grid>
                </Segment>
                <Header textAlign="left" as="h4">Параметры сортировки</Header>
                <Segment color="blue">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width="2">
                                №
                            </Grid.Column>
                            <Grid.Column width="8">
                                Поле
                            </Grid.Column>
                            <Grid.Column width="6">
                                Порядок
                            </Grid.Column>
                        </Grid.Row>
                        <Divider className="modal-sort-divider" />
                        {listOrderByItems()}
                    </Grid>
                </Segment>
            </Modal.Content>
            <ModalActions>
                <Button negative onClick={handleCancel}>Отмена</Button>
                <Button positive onClick={handleSave}>Применить</Button>
            </ModalActions>
        </Modal>
    )
}