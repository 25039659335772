import React, {useEffect} from 'react';
import { Header } from 'semantic-ui-react';
import {useSelector} from 'react-redux';

import '../../components/ModalPayment/styles.css';
import CheckoutSteps, {CHECKOUT_STEP_CONFIRM} from '../../components/CheckoutSteps';

import {closeSearch} from "../../components/NavTopBar/actions";
import {getOrders} from "../Orders/reducer";
import OrderCard from "../../components/OrderCard";
import ModalPayment from "../../components/ModalPayment/ModalPayment";
import {getPaymentMethod} from "../Checkout_2_StepPayment/reducer";
import {PAYMENT_OPTION_ONLINE} from "../Checkout_2_StepPayment/actions";
import {useParams} from "react-router";

export default function CheckoutStepConfirm(props) {

    const {userData, userLocation, mixpanel} = props;

    const matchParams = useParams();
    const orderKey = matchParams.orderKey;

    const orders = useSelector((state) => getOrders(state.orders));
    const placedOrder = orders.find((obj) => obj.order_key === orderKey,);
    const payment_option = useSelector((state) => getPaymentMethod(state.payment));

    const needsPayment = (placedOrder.status === 'pending' || placedOrder.status === 'on-hold') &&
        payment_option === PAYMENT_OPTION_ONLINE;
    const [paymentOpen, setPaymentOpen] = React.useState(needsPayment);

    useEffect(() => {
        if (mixpanel)
            mixpanel.track('Checkout Order Placed', { ...userLocation, ...userData, order_id: placedOrder.id, payment_option });
        window.scrollTo(0, 0);
    }, []);

    return (
          <div>
              <Header textAlign="center">Заказ создан!</Header>
              <CheckoutSteps step={CHECKOUT_STEP_CONFIRM} />
              <OrderCard
                  order={placedOrder}
                  {...props}
              />
              <ModalPayment
                  open = {paymentOpen}
                  headerIcon = "payment"
                  headerText = "Оплата заказа"
                  order = {placedOrder}
                  handleOpen = {() => setPaymentOpen(true)}
                  handleClose = {() => setPaymentOpen(false)}
                  {...props}
              />
          </div>
    );
  }



