import { combineReducers } from 'redux';
import _ from 'lodash';
import { REQUEST_APPLY_COUPON, RECEIVE_APPLY_COUPON, REMOVE_COUPON } from './actions';
import config from '../../config/config';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST_APPLY_COUPON:
      return true;
    case RECEIVE_APPLY_COUPON:
      return false;
    default:
      return state;
  }
};

const coupons = (state = [], action) => {
  switch (action.type) {
    case REQUEST_APPLY_COUPON:
      return state;
    case RECEIVE_APPLY_COUPON:
      return _.unionBy([action.couponCode], state, 'coupon_code');
    default:
      return state;
  }
};

export const getDiscount = state => state.coupons;
export const getDiscountFetching = state => state.isFetching;

const discount = combineReducers({
  isFetching,
  coupons,
});
export default discount;
