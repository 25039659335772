import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {
    Card, Button, Header, Grid, Icon, Label, Segment, Rating, Loader, Dimmer, Message,
} from 'semantic-ui-react';

import {getTaskUpdating, getTaskOptions} from '../../views/Tasks/reducer';
import CircularImage from '../CircularImage';
import {updateTask} from '../../views/Tasks/actions';

import './styles.css';
import _, {isNull, isUndefined} from "lodash";
import config, {
    SERVICE_TASK_LEVEL_CLOSED,
    SERVICE_TASK_LEVEL_DISABLED,
    SERVICE_TASK_LEVEL_PREPARING,
    SERVICE_TASK_LEVEL_WORKING, SERVICE_TASK_STATE_CANCELED, SHOW_TASK_PROMPT_CONFIRM, SHOW_TASK_PROMPT_DATE
} from "../../config/config";
import {toastr} from "react-redux-toastr";
import ModalPrompt from "../ModalPrompt";
import ModalDateSelection from "../ModalDateSelection/ModalDateSelection";

export const get_formatted_price = (price, nullValue) => {
    const formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
    });
    if (nullValue && (isUndefined(price) || isNull(price) || Number(price) === 0))
        return nullValue;
    return formatter.format(price);
}
export default function TaskCard(props) {

    const {
        dispatch,
        task,
        image,
        showLoader,
        hideButtonSteps,
        token,
        userData,
        userLocation,
        mixpanel,
        navigate,
        location
    } = props;

    const {pathname} = location;

    const {task_status, task_status_level, task_status_prev, customer_rating, job_dates} = task;

    const [promptOperation, setPromptOperation] = useState({
        operation: {},
        open: false
    });
    const [calendarOperation, setCalendarOperation] = useState({
        operation: {},
        open: false
    });

    const taskOptions = useSelector((state) => getTaskOptions(state.tasks));
    const getLoading = useSelector((state) => getTaskUpdating(state.tasks));

    const forward = _.find(taskOptions.operations, {forward: 1, state_from: task_status});
    const backward = _.find(taskOptions.operations, {backward: 1, state_from: task_status});

    let cancel_restore;
    if (Number(task_status_level) <= SERVICE_TASK_LEVEL_WORKING || task_status === SERVICE_TASK_STATE_CANCELED)
        cancel_restore = (task_status === SERVICE_TASK_STATE_CANCELED
                ? _.find(taskOptions.operations, {restore: 1, state_to: task_status_prev})
                : _.find(taskOptions.operations, {cancel: 1, state_from: task_status})
        );

    let labelColor;
    switch (task_status_level) {
        case SERVICE_TASK_LEVEL_DISABLED:
            labelColor = 'grey';
            break;
        case SERVICE_TASK_LEVEL_PREPARING:
            labelColor = 'orange';
            break;
        case SERVICE_TASK_LEVEL_WORKING:
            labelColor = 'blue';
            break;
        case SERVICE_TASK_LEVEL_CLOSED:
            labelColor = 'green';
            break;
        default:
            labelColor = 'grey';
            break;
    }

    const task_cost_min = get_formatted_price((task.total_cost ? task.total_cost.min : null), 'без оплаты');
    const task_cost_max = get_formatted_price((task.total_cost ? task.total_cost.max : null), 'без оплаты');

    const actionTask = (operationCode, additionInfo = '') => {
        dispatch(updateTask(token, task.id, operationCode, pathname, additionInfo));
        if (mixpanel)
            mixpanel.track('TaskCard Change Status Complete',
                {
                    ...userData, ...userLocation,
                    taskId: task.id,
                    operationCode,
                    additionInfo,
                    customer_rating,
                    pathname
                });
    }

    const task_cost = task_cost_min === task_cost_max ? task_cost_max : 'до ' + task_cost_max;

    const handleOpenTask = () => {
        if (task_status_level === SERVICE_TASK_LEVEL_WORKING)
            navigate(`/task/${task.id}`)
        else
            toastr.warning('Вы сможете открыть карточку когда услуга будет Вами принята в работу');
    }

    const actionPrompt = (inputText) => {
        actionTask(promptOperation.operation.code, inputText);
        setPromptOperation({operation: {}, open: false});
    }

    const promptModalConfirm = () => {
        const {operation: {prompt_header, prompt_text, prompt_input}, open} = promptOperation;
        return (
            <ModalPrompt
                open={open}
                headerIcon="question"
                headerText={prompt_header}
                messageText={prompt_text}
                inputText={prompt_input}
                handleYes={actionPrompt}
                handleNo={() => setPromptOperation({operation: {}, open: false})}
                handleClose={() => setPromptOperation({operation: {}, open: false})}
                handleOpen={() => setPromptOperation({...promptOperation, open: true})}
            />
        );
    }

    const saveAcceptedDate = (acceptedDate) => {
        actionTask(calendarOperation.operation.code, acceptedDate);
    }
    const promptModalDate = () => {
        return (
            <ModalDateSelection
                open={calendarOperation.open}
                handleOpen={() => setCalendarOperation({...calendarOperation, open: true})}
                handleClose={() => setCalendarOperation({...calendarOperation, open: false})}
                callback={saveAcceptedDate}
                header="Выберите дату выполнения работ"
            />
        );
    }

    const onButtonOperationClick = (operationCode) => {
        switch (operationCode.show_prompt) {
            case SHOW_TASK_PROMPT_CONFIRM:
                setPromptOperation({operation: operationCode, open: true});
                break;
            case SHOW_TASK_PROMPT_DATE:
                setCalendarOperation({operation: operationCode, open: true});
                break;
            default:
                actionTask(operationCode.code)
        }
        if (mixpanel)
            mixpanel.track('TaskCard Change Status Button Pressed',
                {...userData, ...userLocation, taskId: task.id, ...operationCode, customer_rating, pathname});
    }

    return (
        <Card centered color="teal" raised>
            <Header className="task-card-header" as="h4" color="red" textAlign="center">
                <div className="task-card-header-order-number">
                    <div dangerouslySetInnerHTML={{__html: 'Заказ клиента'}}/>
                    <div dangerouslySetInnerHTML={{__html: '№' + String(task.id_order)}}/>
                    <div dangerouslySetInnerHTML={{__html: 'от ' + task.order_create_date_str}}/>
                </div>
                <Label className="task-ribbon-state" as="a" color={labelColor} ribon="" attached="bottom right">
                    {_.size(taskOptions) ? taskOptions.statuses[task_status] : ''}
                </Label>
                {_.isUndefined(cancel_restore) ? '' :
                    <Button
                        className={`task-card-button-cancel-restore ${task_status}`}
                        onClick={() => onButtonOperationClick(cancel_restore)}
                        disabled={getLoading === task.id}
                    >
                        {cancel_restore.call_to_action}
                    </Button>}
            </Header>
            {showLoader ?? false ?
                <Dimmer active={getLoading === task.id} inverted>
                    <Loader inverted/>
                </Dimmer>
                : ''
            }
            <Card.Content>
                <Grid onClick={handleOpenTask}>
                    {image === "yes"
                        ? <Grid.Column className="task-card-col-image" width={5}>
                            <CircularImage src={task.image_source} circular={false}/>
                        </Grid.Column>
                        : ''}
                    <Grid.Column className={`task-card-col-product image-${image}`} width={image === "yes" ? 11 : 16}>
                        <Card.Header as="h3">{task.product_name}</Card.Header>
                        {job_dates && job_dates.confirmed_date ?
                            <Message color="blue" className="task-card-confirmed-date">
                                Дата работ подтверждена: {job_dates.confirmed_date}
                            </Message>
                            : ''}
                        {task_status_level === SERVICE_TASK_LEVEL_WORKING && !hideButtonSteps ?
                            <Button primary className="task-card-open-steps">
                                внести результаты
                            </Button>
                            : ''}
                    </Grid.Column>
                </Grid>
                <Segment className="task-card-segment-amount" raised>
                    <div className="task-card-amount"
                         dangerouslySetInnerHTML={{__html: task_cost}}
                    />
                </Segment>
                <div className="task-card-customer-data">
                    <Rating className="rating_client" icon='star' rating={customer_rating ?? 0} maxRating={6}
                            disabled/>
                    <div
                        className="task-card-customer-name"
                        size="tiny"
                        dangerouslySetInnerHTML={{__html: task.customer_address.first_name + ' ' + task.customer_address.last_name}}
                    />
                    {task.customer_address.is_juridic ==='true' ? (
                        <div className="task-card-customer-name" size="tiny">
                            <div dangerouslySetInnerHTML={{__html: task.customer_address.company + ', ' + 'ИНН: ' + task.customer_address.company_inn}}/>
                            {task.customer_address.company_job_title ? (
                                <div dangerouslySetInnerHTML={{__html: 'Должность: ' + task.customer_address.company_job_title}}/>
                            ): null}
                        </div>
                    ) : null}
                    <div size="tiny" dangerouslySetInnerHTML={{__html: task.customer_address.address_1}}/>

                    {task.order_notes
                        ? <div size="tiny"
                               dangerouslySetInnerHTML={{__html: 'Примечание к заказу: ' + task.order_notes}}/>
                        : ''}
                </div>
                <Segment className="task-card-phone-segment" color="blue">
                    <div className="field-info">
                        <a href={'tel:' + task.customer_address.phone}>
                            <Icon name="call" size="large" color="blue"/>
                            <a color="blue">{task.customer_address.phone}</a>
                        </a>
                    </div>
                    {task.customer_address.email
                        ? <div className="field-info">
                            <a href={'mailto:' + task.customer_address.email}>
                                <Icon name="mail" size="large" color="blue"/>
                                <a color="blue">{task.customer_address.email}</a>
                            </a>
                        </div>
                        : <div className="field-info">
                            <Icon name="mail" size="large" color="blue"/>
                            <a color="blue">Почта не указана</a>
                        </div>}
                    <a>
                        <div size="tiny"
                             dangerouslySetInnerHTML={{__html: 'Часовой пояс: ' + task.order_create_date.timezone}}/>
                    </a>
                </Segment>
            </Card.Content>
            {isUndefined(forward) && isUndefined(backward) ? '' :
                <Card.Content extra>
                    <Segment basic className="task-card-action-buttons">
                        <Button.Group fluid>
                            {isUndefined(forward) ? ''
                                : <Button
                                    primary
                                    icon
                                    compact
                                    onClick={() => onButtonOperationClick(forward)}
                                >
                                    {forward.call_to_action + ' '}
                                    <Icon name="check"/>
                                </Button>
                            }
                            {isUndefined(backward) ? ''
                                : <Button
                                    secondary
                                    icon
                                    compact
                                    onClick={() => onButtonOperationClick(backward)}
                                >
                                    {backward.call_to_action + ' '}
                                    <Icon name="undo"/>
                                </Button>}
                        </Button.Group>
                    </Segment>
                </Card.Content>
            }
            {promptModalConfirm()}
            {promptModalDate()}
        </Card>
    );
}
