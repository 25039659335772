import React from 'react';
import PropTypes from 'prop-types';
import {Image} from 'semantic-ui-react';

import './styles.css';

const CircularImage = props => (
  <Image
    circular={ props.circular }
    size="small"
    src={ props.src }
    style={{
      width: props.width,
      height: props.height,
    }}
  />
);

CircularImage.propTypes = {
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.string,
    circular: PropTypes.bool,
};

CircularImage.defaultProps = {
    src: '',
    width: 120,
    height: "auto",
    circular: true,
};

export default CircularImage;
