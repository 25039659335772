import { combineReducers } from 'redux';
import {
  REQUEST_LOCATION_DATA, SET_LOCATION_DATA,
} from './actions';

const location = (state = [], action = {}) => {
  switch (action.type) {
    case SET_LOCATION_DATA:
      return action.data;

    case REQUEST_LOCATION_DATA:
    default:
      return state;
  }
};

export const getLocationPermission = (state) => state.location.condition;
export const getLocationData = (state) => state.location;

const userLocation = combineReducers({
  location,
});

export default userLocation;
