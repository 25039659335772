import 'whatwg-fetch';
import config from '../../config/config';

export const REQUEST_APPLY_COUPON = 'REQUEST_APPLY_COUPON';
export const RECEIVE_APPLY_COUPON = 'RECEIVE_APPLY_COUPON';
export const REMOVE_COUPON = 'REMOVE_COUPON';

export const removeCoupon = couponCode => ({
  type: REMOVE_COUPON,
  couponCode,
});

export const requestApplyCoupon = couponCode => ({
  type: REQUEST_APPLY_COUPON,
  couponCode,
});

export const receiveApplyCoupon = (couponCode, couponData) => ({
  type: RECEIVE_APPLY_COUPON,
  couponCode,
  couponData,
});

export const fetchApplyCoupon = (couponCode, params) => (dispatch) => {
  dispatch(requestApplyCoupon(couponCode));
  const paramsJson = JSON.stringify(params);
  const url = config.API_APPLY_DISCOUNT_URL;

  return fetch(
    url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: paramsJson,
    },
  )
    .then(response => response.json())
    .then(json => dispatch(receiveApplyCoupon(couponCode, json)))
    .catch(() => {
      dispatch(receiveApplyCoupon(couponCode,[]));
    });
};
