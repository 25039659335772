import { combineReducers } from 'redux';

import {RECEIVE_COUNTRIES, REQUEST_COUNTRIES, SET_ADDRESS_FIELDS} from './actions';

const initialState = {
  fields: [],
}
function addressFields(state = initialState, action = 0) {

  switch (action.type) {
    case SET_ADDRESS_FIELDS:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

const countriesList = (state = [], action = 0) => {
  switch (action.type) {
    case RECEIVE_COUNTRIES:
      return action.data;
    default:
      return state;
  }
};

const isFetching = (state = false, action = {}) => {
  switch (action.type) {
    case REQUEST_COUNTRIES:
      return true;
    case RECEIVE_COUNTRIES:
      return false;
    default:
      return state;
  }
};
export const getAddressFields = (state) => state.addressFields;

export const addressField = (state = [], fieldName = '') => state.addressFields['fieldName'];

export const isCountriesFetching = (state) => state.isFetching;

export const getCountries = (state) => state.countriesList;

const address = combineReducers({
  addressFields, countriesList, isFetching
});

export default address;
