import 'whatwg-fetch';
import config from '../../config/config';
import {toastr} from "react-redux-toastr";

export const REQUEST_FAQ = 'REQUEST_FAQ';
export const RECEIVE_FAQ = 'RECEIVE_FAQ';
export const RECEIVE_1PAGE_FAQ = 'RECEIVE_1PAGE_FAQ';
export const FAQ_PAGE_COUNT = 20;

export const requestFAQ = (page) => ({
    type: REQUEST_FAQ,
    page,
});

export const receiveFAQ = (faq) => ({
    type: RECEIVE_FAQ,
    faq,
});

export const receive1PageFAQ = (faq) => ({
    type: RECEIVE_1PAGE_FAQ,
    faq,
});

export const fetchFAQ = (params = {}) => (dispatch) => {
    dispatch(requestFAQ(params.page ?? 1));

    let url;
    if (params && params.id) {
        url = config.API_FAQ_URL + String(params.id);
    } else {
        url = config.API_FAQ_URL
            + '?'
            + Object.keys(params)
                .map((k) => k + '=' + encodeURIComponent(params[k]))
                .join('&');
    }

    return fetch(url)
        .then((response) => response.json())
        .then((json) => {
            if (json.code === 200)
            {
                dispatch(params.page === 1 ? receive1PageFAQ(json.data) : receiveFAQ(json.data))
            }
        })
        .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};