import 'whatwg-fetch';
import config from '../../config/config';
import {toastr} from "react-redux-toastr";

export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const CATEGORIES_PAGE_COUNT = 20;

export const requestCategories = (page, parentCat) => ({
  type: REQUEST_CATEGORIES,
  parent: parentCat,
  page
});

export const receiveCategories = (page, categories, parentCat) => ({
  type: RECEIVE_CATEGORIES,
  parent: parentCat,
  categories,
  page
});

export const fetchCategories = (parent, params = {}) => (dispatch) => {

  dispatch(requestCategories(params.page, parent));

  let url;
  if (params && params.id) {
    url = config.API_CATEGORIES_URL + String(params.id);
  } else {
    url =
      config.API_CATEGORIES_URL +
      '?' +
      Object.keys(params)
        .map(k => k + '=' + encodeURIComponent(params[k]))
        .join('&');
  }

  return fetch(url)
    .then(response => response.json())
    .then(json => dispatch(receiveCategories(params.page, json, parent)))
    .catch((ex) => {
      toastr.error('Ошибка запроса: ' + String(ex));
    });
};
