import React, {useEffect} from 'react';

import {fetchProducts, PRODUCTS_PAGE_COUNT} from '../Products/actions';

import ProductsList from '../../components/ProductsList';

import "./style.css"
import {useSelector} from "react-redux";
import {getProducts, getProductsFetching, getProductsHasMore} from "../Products/reducer";
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import InfiniteView from "../../components/InfiniteView";

export default function Home (props) {

    const {dispatch, userData, userLocation, mixpanel, routeAction, isPageReloaded} = props;

    const loading = useSelector((state) => getProductsFetching(state.products));
    const products = useSelector((state) => getProducts(state.products));
    const hasMore = useSelector((state) => getProductsHasMore(state.products));

    useEffect(() => {
        if ( routeAction === 'PUSH' || isPageReloaded ) {
            readProducts(1);
            setWindowScrollPosition();
        }
        if (mixpanel)
            mixpanel.track('Products List View', { ...userLocation, ...userData });
    }, []);

    useEffect(() => {
        if (isPageReloaded) {
            setWindowScrollPosition();
        }
    }, [props]);

     function  loadProducts() {
       const nextPage = Math.round(products.length / PRODUCTS_PAGE_COUNT) + 1;
       readProducts(nextPage);
      }

      function readProducts(page) {
        dispatch(fetchProducts({
          services: 'yes',
          page,
          order: 'asc',
          orderby: 'title',
          per_page: PRODUCTS_PAGE_COUNT,
        }));
      }

      return (
            <InfiniteView
                dataLength={products.length}
                nextFetch={loadProducts}
                hasMore={hasMore}
                dataList={(
                    <ProductsList products={products} title="Продукты" {...props}/>
                )}
                mustAuth={false}
                isLoading={loading}
                loaderText="Загружаем продукты ..."
                zeroText="Продукты отсутствуют"
            />
    );
}
