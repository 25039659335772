import React from 'react';
import './style.css';
import ReactPlayer from 'react-player'
import {Header} from 'semantic-ui-react';
import { useState, useEffect, useRef } from 'react';
import {useWindowDimensions} from "../WindowDimensions";

const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
    };
};

const ModalPlayer = ({active, setActive, url, headerText}) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const resizeObserverRef = useRef(null);

    useEffect(() => {
        const debouncedHandleResize = debounce(() => {
            if (resizeObserverRef.current) {
                const rect = resizeObserverRef.current.getContentRect();
                setDimensions({ width: rect.width, height: rect.height });
            }
        }, 20);

        const observer = new ResizeObserver(debouncedHandleResize);
        if (active) {
            observer.observe(document.querySelector('.modal-window'));
        } else {
            observer.disconnect();
        }

        return () => observer.disconnect();
    }, [active]);

    const [zoom, setZoom] = useState(1.0);

    const handleZoomIn = () => {
        setZoom(Math.min(zoom + 0.1, 2.0));
    };

    const handleZoomOut = () => {
        setZoom(Math.max(zoom - 0.1, 0.4));
    };
    const {height} = useWindowDimensions();

    return(
        <div className={active ? "modal-window active" : "modal-window"} onClick={() => setActive(false)}>
            <div className={active ? "modal-content active" : "modal-content"} onClick={e=>e.stopPropagation()}>
                <div className="header-content">
                    <Header>{headerText}</Header>
                    <button className="close" onClick={()=>setActive(false)}>&times;</button>
                </div>
                <hr/>
                <div className="player-wrapper">
                    <ReactPlayer
                        config={{file: {attributes: {controlsList: 'nodownload'}}}}
                        onContextMenu={e => e.preventDefault()}
                        className="react-player"
                        url={url}
                        style={{
                            transform: `scale(${zoom})`,
                        }}
                        controls={true}
                        playing={true}
                        width='100%'
                        height={height - 200}
                    />
                </div>
            </div>
        </div>
    )
}
export default ModalPlayer;