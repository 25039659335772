import React, {useEffect, useState} from 'react';
import {
    Modal, Button, Header, Form, Message, Icon, Label,
} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import {isAuthVisible, isSearchVisible} from '../NavTopBar/reducer';
import {closeAuth, closeSearch} from '../NavTopBar/actions';
import { fetchToken } from './actions';
import './UserLogin.css';
import {getUserFetching, getUserLoginError} from './reducer';
import {closeMenu} from "../NavBottomBar/actions";

export const cleanMessage = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");

export default function UserLogin (props) {

    const {userData, menuVisible, searchVisible, dispatch, mixpanel } = props;
    const showModal = useSelector((state) => isAuthVisible(state.navtopbar));
    const isFetching = useSelector((state) => getUserFetching(state.userLogin));
    const messageLoginError= useSelector((state) => getUserLoginError(state.userLogin));

    const defaultSettings = {
        phone: '',
        email: userData.login ?? '',
        password: '',
        emailError: false,
        passwordError: false,
        showPassword: false,
    };

    const [state, setState] = useState(defaultSettings);

    useEffect(() => {
      if (menuVisible)
        dispatch(closeMenu());

        if (searchVisible)
            dispatch(closeSearch());

      if (mixpanel)
          mixpanel.track('Login Dialog Open', { ...userData });

      setState(defaultSettings);

    }, [showModal === true]);

    function validateForm() {
        setState({ ...state, emailError: !state.email.length, passwordError: !state.password.length});
        return (state.email.length && state.password.length);
    }
    function submitForm() {
        if (validateForm())
            dispatch(fetchToken(state.email, state.password));
    }

    function handleSetShowPassword(show) {
        setState({...state, showPassword: show});
    }

    return (
      <Modal
        dimmer
        closeIcon
        open={showModal}
        onClose={() => dispatch(closeAuth())}
        centered
        closeOnDimmerClick
        className="user-login"
      >
        <Header icon="sign in" content="Авторизация" />
        <Modal.Content>
            <Modal.Description>
              { messageLoginError !== '' ? (
                <Message
                  negative
                  icon="user times"
                  header="Вы не авторизованы"
                  content={cleanMessage(messageLoginError)}
                />
              ) : '' }
              <Form error={state.emailError || state.passwordError} onSubmit={submitForm} loading={Boolean(isFetching)}>
                <Form.Field>
                  <Form.Input
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder="Логин или электронная почта"
                    onChange={(e) => setState({ ...state, email: e.target.value })}
                    error={state.emailError}
                    type="email"
                    name="login"
                    autoComplete="on"
                    value={state.email}
                  />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        fluid
                        placeholder="Пароль"
                        onChange={(e) => setState({...state, password: e.target.value})}
                        error={state.passwordError}
                        type = {state.showPassword ? "text" : "password"}
                        name="password"
                        autoComplete="on"
                        value={state.password}
                        iconPosition="left"
                        labelPosition="right">
                            <Icon name="key"/>
                            <input/>
                            <Label
                                icon={state.showPassword ? "eye" : "eye slash"}
                                onClick={() => handleSetShowPassword(!state.showPassword)}
                                className="user-login-toggle-show-password"
                            />
                    </Form.Input>
                </Form.Field>
              </Form>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => dispatch(closeAuth())}>Отмена</Button>
          <Button positive onClick={submitForm}>Войти</Button>
        </Modal.Actions>
      </Modal>
    );
}


