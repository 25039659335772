import React, {useEffect} from 'react';
import {Header, Modal} from 'semantic-ui-react';
import {TINKOFF_SUCCESS_PAGE} from "../../config/config";

import PayForm from "../TinkoffOplata/PayForm";
import {TINKOFF_TERMINAL_ID} from "../../config/secrets";

export default function ModalPayment(props) {

    const {open, headerIcon, headerText, order, handleOpen, handleClose} = props;

    const [stateLoading, setStateLoading] = React.useState(true);

    function openPaymentModal() {
        handleOpen();
        setStateLoading(true);
    }

    function closePaymentModal() {
        setLoaded();
        handleClose();
    }

    function setLoaded() {
        setStateLoading( false);
    }

    const form = {
        terminalkey: TINKOFF_TERMINAL_ID,
        frame: 'true',
        language: 'ru',
        amount: order.total,
        order: order.id,
        orderkey: order.order_key,
        description: 'Оплата за заказ',
        name: order.billing.last_name + ' ' + order.billing.first_name,
        email: order.billing.email,
        phone: order.billing.phone,
        successurl: window.location.origin + TINKOFF_SUCCESS_PAGE,
        route: window.location.hash.slice(1),
        handleclose: closePaymentModal,
        handleloading: setLoaded,
        stateLoading: stateLoading,
    };

    return (
        <Modal
            closeIcon
            dimmer
            centered
            open={ open }
            onOpen={ openPaymentModal }
            onClose={ closePaymentModal }
            closeOnDimmerClick={ true }
            className="modal-payment"
        >
            <Header icon={ headerIcon } content={ headerText } />
            <Modal.Content>
                <PayForm formData={ form } {...props}/>
            </Modal.Content>
        </Modal>
    );
}
