import { combineReducers } from 'redux';
import _ from 'lodash';
import {
  REQUEST_SEARCH,
  RECEIVE_SEARCH,
  SEARCH_PAGE_COUNT,
  SEARCH_CONTEXT_UNSET,
  SEARCH_CONTEXT_DEFAULT,
  SET_SEARCH_CONTEXT, RECEIVE_SEARCH_UPDATE
} from './actions';
import {isConsole} from "react-device-detect";
import {mergeObjectArrays, store} from "../../configureStore";

const items = (state = [], action) => {
  switch (action.type) {
    case REQUEST_SEARCH:
      return state;
    case RECEIVE_SEARCH:
      if (action.page === 1)
        return action.items;
      else
        return mergeObjectArrays(action.items, state);
    case RECEIVE_SEARCH_UPDATE:
      return mergeObjectArrays(action.items, state);
    default:
      return state;
  }
};

const isFetching = (state = {fetching: false, page: 1}, action) => {
  switch (action.type) {
    case REQUEST_SEARCH:
      return {fetching: true, page: action.page};
    case RECEIVE_SEARCH:
    case RECEIVE_SEARCH_UPDATE:
      return {...state, fetching: false};
    default:
      return state;
  }
};

const hasMore = (state = false, action) => {
  switch (action.type) {
    case REQUEST_SEARCH:
      return true;
    case RECEIVE_SEARCH:
      return action.items.length >= SEARCH_PAGE_COUNT;
    default:
      return state;
  }
};

const fromContext = (state = SEARCH_CONTEXT_UNSET, action) => {
  switch (action.type) {
    case REQUEST_SEARCH:
    case RECEIVE_SEARCH:
      return action.context;
    default:
      return state;
  }
};

const toContext = (state = SEARCH_CONTEXT_DEFAULT, action) => {
  switch (action.type) {
    case SET_SEARCH_CONTEXT:
      return action.value;
    default:
      return state;
  }
};

export const getSearch = state => state.items;
export const getSearchFetching = state => state.isFetching.fetching && state.isFetching.page === 1;
export const getHasMore = state => state.hasMore;
export const getFromContext = state => state.fromContext;
export const getToContext = state => state.toContext;

export default combineReducers({
  items,
  isFetching,
  hasMore,
  fromContext,
  toContext
});
