import React, { useEffect} from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import { Segment, Header, Button } from 'semantic-ui-react';
import {getCart, getTotalPrice, hasOnlyVirtuals} from './reducer';
import CartProduct from './CartProduct';
import CardSummary from '../../components/CardSummary/CardSummary';

export default function Cart(props) {

  const {userLocation, userData, mixpanel} = props;
  const cart = useSelector((state) => getCart(state.cart));
  const total_price = useSelector((state) => getTotalPrice(state.cart));
  const only_virtuals = useSelector((state) => hasOnlyVirtuals(state.cart));

  useEffect(() => {
    if (mixpanel)
      mixpanel.track('Cart View', { ...userLocation, ...userData, cart });
    window.scrollTo(0, 0);
  }, []);

  return _.isEmpty(cart) ? (
      <Segment textAlign="center">Ваша корзина пока еще пуста</Segment>
    ) : (
      <div>
        <Header textAlign="center">Корзина</Header>
        {cart.map((product) => (
          <CartProduct
            key={_.isNil(product.variationId) ? product.id : product.variationId}
            product={product}
            show_buttons={true}
            {...props}
          />
        ))}
        <CardSummary total_products={total_price} />
        <Link to= { cart.length <= config.MAX_PRODUCTS_IN_CART && only_virtuals? "/checkout-step-shipping" : "#" }>
          <Button
              className="goto-checkout"
              color="green"
              fluid
              disabled={ cart.length > config.MAX_PRODUCTS_IN_CART || !only_virtuals}
          >
            Оформить заказ
          </Button>
        </Link>
      </div>
    );
}
