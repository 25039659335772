import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _, {isArray} from 'lodash';
import {Loader, Container, Dimmer} from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {fetchOrders, ORDERS_PAGE_COUNT} from "./actions";
import {getOrders, getOrdersFetching, getOrdersHasMore} from "./reducer";
import OrdersList from "../../components/OrdersList";

import "./style.css"

export default function Orders(props) {

  const {dispatch, userData, userLocation, mixpanel, routeAction, token, isPageReloaded} = props;

  const loading = useSelector((state) => getOrdersFetching(state.orders));
  const orders = useSelector((state) => getOrders(state.orders));
  const hasMore = useSelector((state) => getOrdersHasMore(state.orders));

  useEffect(() => {
    if ( routeAction === 'PUSH' || isPageReloaded ) {
      if (token)
        readOrders(1);
        window.scrollTo(0, 0);
    }
    if (mixpanel)
      mixpanel.track('Orders List View', { ...userLocation, ...userData });
  }, []);

  useEffect(() => {
    if (isPageReloaded)
      window.scrollTo(0, 0);
  }, [props]);

  const loadOrders = () => {
    if (hasMore) {
      const nextPage = Math.round(orders.length / ORDERS_PAGE_COUNT) + 1;
      readOrders(nextPage);
    }
  };

  const readOrders = (page) => {
    dispatch(fetchOrders(token, {
      page,
      order: 'desc',
      orderby: 'id',
      per_page: ORDERS_PAGE_COUNT,
    }));
  };

  if (loading) {
    return (
        <Dimmer active={ loading } inverted className="page-loader">
          <Loader>Загружаем заказы ...</Loader>
        </Dimmer>
    );
  }

  if (orders.length === 0 || !token) {
    return ( <Container><p>{token ? 'Заказы отсутствуют' : 'Для получения доступа к списку, пожалуйста, авторизуйтесь'}</p></Container> );
  }

  return (
      <InfiniteScroll
            dataLength={orders.length}
            next={loadOrders}
            hasMore={hasMore}
            loader={<h4 className="scroll-loader-wraploadingper">... загрузка ...</h4>}
            endMessage={(
                <p style={{ textAlign: 'center' }}>
                  <b>Конец списка</b>
                </p>
            )}
          >
        <OrdersList
          orders={_.orderBy(orders, ['id'], ['desc'])}
          title="Заказы"
          {...props}
        />
      </InfiniteScroll>
  );

}

