import { combineReducers } from 'redux';
import _ from 'lodash';
import {
  REQUEST_TASKS,
  RECEIVE_TASKS,
  RECEIVE_1PAGE_TASKS,
  TASKS_PAGE_COUNT,
  CHANGE_TASK,
  SET_TASK_OPTIONS,
  SET_TASK_FILTER,
  SET_TASK_ORDERBY, RECEIVE_CHANGED_TASK
} from './actions';
import {mergeObjectArrays} from "../../configureStore";

const options = (state = [], action = {}) => {
  switch (action.type) {
    case SET_TASK_OPTIONS:
      return action.options;
    default:
      return state;
  }
};

const filter = (state = [], action = {}) => {
  switch (action.type) {
    case SET_TASK_FILTER:
      return action.filter;
    default:
      return state;
  }
};

const orderby = (state = [], action = {}) => {
  switch (action.type) {
    case SET_TASK_ORDERBY:
      return action.orderby;
    default:
      return state;
  }
};

const items = (state = [], action = {}) => {
  switch (action.type) {
    case RECEIVE_TASKS:
    case RECEIVE_CHANGED_TASK:
      return mergeObjectArrays(action.tasks, state);

    case RECEIVE_1PAGE_TASKS:
      return action.tasks;
    default:
      return state;
  }
};

const hasMore = (state = false, action = 0) => {
  switch (action.type) {
    case REQUEST_TASKS:
      return true;
    case RECEIVE_TASKS:
    case RECEIVE_1PAGE_TASKS:
      return action.tasks.length >= TASKS_PAGE_COUNT;
    default:
      return state;
  }
};

const isFetching = (state = {fetching: false, page: 1, id: 0}, action = {}) => {
  switch (action.type) {
    case REQUEST_TASKS:
      return {...state, fetching: true, page: action.page};
    case CHANGE_TASK:
      return {...state, fetching: true, id: action.id};
    case RECEIVE_TASKS:
    case RECEIVE_1PAGE_TASKS:
    case RECEIVE_CHANGED_TASK:
      return {...state, fetching: false, id: 0};
    default:
      return state;
  }
};

export const getTasks = (state) => state.items;
export const getTasksFetching = (state) => (state.isFetching.fetching && state.isFetching.page === 1 && state.isFetching.id === 0);
export const getTaskUpdating = (state) => (state.isFetching.id);
export const getTasksHasMore = (state) => state.hasMore;
export const getTaskOptions = (state) => state.options;
export const getTaskFilter = (state) => state.filter;
export const getTaskOrderBy = (state) => state.orderby;

export default combineReducers({
  options,
  items,
  isFetching,
  hasMore,
  filter,
  orderby,
});
