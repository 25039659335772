import { combineReducers } from 'redux';
import {PAYMENT_OPTION_ONLINE, SET_PAYMENT_METHOD} from './actions';

const paymentMethod = (state = PAYMENT_OPTION_ONLINE, action) => {
  switch (action.type) {
    case SET_PAYMENT_METHOD:
      return action.paymentOption;
    default:
      return state;
  }
};

export const getPaymentMethod = state => state.paymentMethod;

const payment = combineReducers({
  paymentMethod,
});
export default payment;
