import React, {useEffect} from 'react';
import ReactDOM from "react-dom/client";
import {Provider, useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {HistoryRouter} from "redux-first-history/rr6";
import {PersistGate} from 'redux-persist/integration/react';
import {Loader} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import {persistor, routerHistory, store} from './configureStore';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import Home from './views/Home';
import Products from './views/Products';
import Categories from './views/Categories';
import Product from './views/Product';
import Cart from './views/Cart';
import Search from './views/Search';
import CheckoutStepShipping from './views/Checkout_1_StepShipping';
import CheckoutStepPayment from './views/Checkout_2_StepPayment';
import Tasks from './views/Tasks';
import Task from './views/Task';
import FAQ from './views/FAQ';
import Instructions from './views/Instructions';

import './index.css';
import CheckoutStepConfirm from "./views/Checkout_3_StepConfirm";
import Orders from "./views/Orders";
import Order from "./views/Order";
import {closeMenu} from "./components/NavBottomBar/actions";
import {closeSearch} from "./components/NavTopBar/actions";
import {isSearchVisible} from "./components/NavTopBar/reducer";
import {getAuthToken, getUserData, getUserLoggedIn} from "./components/UserLogin/reducer";
import {getLocationData} from "./components/GeoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";
import {isMenuVisible} from "./components/NavBottomBar/reducer";
import {isArray} from "lodash";

const root = ReactDOM.createRoot(document.getElementById("root"));

console.log(`App "${process.env.REACT_APP_NAME}" version ${process.env.REACT_APP_VERSION} is running in ${process.env.REACT_APP_ENV} mode`)

const withRouter = (Component) => (props) => {

    const router = useSelector((state) => state.router);

    const defaultProps = {
        navigate: useNavigate(),
        dispatch: useDispatch(),
        searchVisible: useSelector((state) => isSearchVisible(state.navtopbar)),
        menuVisible: useSelector((state) => isMenuVisible(state.navbottombar)),
        userData: useSelector((state) => getUserData(state.userLogin)),
        userLocation: useSelector((state) => getLocationData(state.userLocation)),
        isLoggedIn: useSelector((state) => getUserLoggedIn(state.userLogin)),
        token: useSelector((state) => getAuthToken(state.userLogin)),
        mixpanel: useMixpanel(),
        routeAction: router.action,
        location: router.location,
        previousLocations: router.previousLocations,
        isPageReloaded: router.action === 'POP' && isArray(router.previousLocations) && _.size(router.previousLocations) === 1,
    }
    const {menuVisible, searchVisible, dispatch} = defaultProps;

    useEffect(() => {
        if (menuVisible)
            dispatch(closeMenu());

        if (searchVisible)
            dispatch(closeSearch());
    }, []);

    return <Component {...defaultProps}/>;
}

root.render(
  <Provider store={store}>
    <PersistGate loading={<Loader active />} persistor={persistor}>
      <HistoryRouter history={routerHistory}>
        <App>
          <Routes>
              <Route exact path={`/`} Component={withRouter(Home)} />
              <Route path={`/categories/:categId`} Component={withRouter(Categories)} />
              <Route path={`/products/:categId`} Component={withRouter(Products)} />
              <Route path={`/product/:productId`} Component={withRouter(Product)} />
              <Route path={`/tasks`} Component={withRouter(Tasks)} />
              <Route path={`/task/:taskId`} Component={withRouter(Task)} />
              <Route path={`/search/:search`} Component={withRouter(Search)} />
              <Route path={`/cart`} Component={withRouter(Cart)} />
              <Route path={`/checkout-step-shipping`} Component={withRouter(CheckoutStepShipping)} />
              <Route path={`/checkout-step-payment`} Component={withRouter(CheckoutStepPayment)} />
              <Route path={`/checkout-step-confirm/:orderKey`} Component={withRouter(CheckoutStepConfirm)} />
              <Route path={`/orders`} Component={withRouter(Orders)} />
              <Route path={`/orders/:orderId`} Component={withRouter(Order)} />
              <Route path={`/faq`} Component={withRouter(FAQ)} />
              <Route path={`/instructions`} Component={withRouter(Instructions)} />

          </Routes>
        </App>
      </HistoryRouter>
    </PersistGate>
  </Provider>
);

//registerServiceWorker();

export default withRouter;
