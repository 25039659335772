import { combineReducers } from 'redux';
import _ from 'lodash';
import { REQUEST_SHIPPING_OPTIONS, RECEIVE_SHIPPING_OPTIONS, SET_SHIPPING_METHOD, SET_SHIPPING_TYPE }
  from './actions';
import config from '../../config/config';

const shippingType = (state = 0, action) => {
  switch (action.type) {
    case SET_SHIPPING_TYPE:
      return action.deliveryType;
    default:
      return state;
  }
};

const shippingMethod = (state = 0, action) => {
  switch (action.type) {
    case SET_SHIPPING_METHOD:
      return action.deliveryMethod;
    default:
      return state;
  }
};

const shippingOptions = (state = [], action) => {
  switch (action.type) {
    case REQUEST_SHIPPING_OPTIONS:
      return state;
    case RECEIVE_SHIPPING_OPTIONS:
      if (Array.isArray(action.shippingOptions)) {
        return _.unionBy(action.shippingOptions, state, 'key');
      }
      return _.unionBy([action.shippingOptions], state, 'key');
    default:
      return state;
  }
};

const isFetching = (state = 0, action) => {
  switch (action.type) {
    case REQUEST_SHIPPING_OPTIONS:
      return true;
    case RECEIVE_SHIPPING_OPTIONS:
      return false;
    default:
      return state;
  }
};

export const getShippingOptions = state => state.shippingOptions;
export const getShippingOption = (state = [], searchMethod = 0, searchType = 0) =>
  _.find(state.shippingOptions, { key: searchMethod + '::' + searchType });

export const getOption = (state = [], searchMethod = 0, searchType = 0, searchAddress = '') => {
  const obj = getShippingOption(state, searchMethod, searchType);
  if (!obj) return { isExist: false, isActual: false };
  return {
    isExist: (!!(obj.data && obj.data.shipping_details)),
    isActual: (obj.data && obj.data.meta ? obj.data.meta.addressFull === searchAddress : ''),
    shippingCost: (obj.data && obj.data.shipping_details ?
      obj.data.shipping_details.total_cost : null),
    shippingCostISO: (obj.data && obj.data.shipping_details ?
      obj.data.shipping_details.total_cost + ' ' + config.CURRENCY : 'нет доставки'),
    shippingDays: (obj.data && obj.data.shipping_details ?
      'за ' + obj.data.shipping_details.delivery_days_max + ' дн.' : ''),
  };
};

export const getShippingOptionFetching = state => state.isFetching;

export const getShippingMethod = state => state.deliveryMethod;

export const getShippingType = state => state.shippingType;

export const getShippingCost = state =>
  getOption(state, getShippingMethod(state), getShippingType(state)).shippingCost;

const shipping = combineReducers({
  shippingOptions,
  isFetching,
  deliveryMethod: shippingMethod,
  shippingType,
});
export default shipping;
