import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';

import ShippingTypes from './ShippingTypes';
import './styles.css';

class ShippingCard extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <Card centered className="delivery-details-card">
        <Card.Content>
          <ShippingTypes />
        </Card.Content>
      </Card>
    );
  }
}

ShippingCard.propTypes = {};

export default ShippingCard;
