import { combineReducers } from 'redux';
import _ from 'lodash';
import {
  REQUEST_PRODUCTS, RECEIVE_PRODUCTS, RECEIVE_1PAGE_PRODUCTS, PRODUCTS_PAGE_COUNT,
} from './actions';
import {mergeObjectArrays} from "../../configureStore";

const items = (state = [], action = 0) => {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return state;
    case RECEIVE_PRODUCTS:
      return mergeObjectArrays(action.products, state);
    case RECEIVE_1PAGE_PRODUCTS:
      return action.products;
    default:
      return state;
  }
};

const hasMore = (state = false, action) => {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return true;
    case RECEIVE_PRODUCTS:
    case RECEIVE_1PAGE_PRODUCTS:
      return action.products.length >= PRODUCTS_PAGE_COUNT;
    default:
      return state;
  }
};

const isFetching = (state = {fetching: false, page: 1}, action) => {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return {fetching: true, page: action.page};
    case RECEIVE_PRODUCTS:
    case RECEIVE_1PAGE_PRODUCTS:
      return {...state, fetching: false};
    default:
      return state;
  }
};

export const getProducts = (state, category = null) => {
  if (category === null) {
    return state.items;
  }

  return state.items;
  /*
  return state.items.filter(product =>
    Array.isArray(product.categories) &&
    !_.isNil(_.find(product.categories, { id: Number(category) })));
*/
};

export const getProductsFetching = (state) => state.isFetching.fetching && state.isFetching.page === 1;
export const getProductsHasMore = (state) => state.hasMore;

export default combineReducers({
  items,
  isFetching,
  hasMore,
});