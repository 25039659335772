import React from 'react';
import { useSelector } from 'react-redux';

import {
    Card, Loader, Button, Dimmer
} from 'semantic-ui-react';
import {getPlaceOrderInProgress} from "../../views/Orders/reducer";
import MiniOrderCard from "./MiniOrderCard";
import CardSummary from "../CardSummary/CardSummary";
import CartProduct from "../../views/Cart/CartProduct";
import OrderCardHeader from "./OrderCardHeader";
import {addProduct, setQuantity} from "../../views/Cart/actions";
import {isCountriesFetching} from "../AddressCard/reducer";
import {
    ADDRESS_ADDINFO,
    ADDRESS_ADDRESS, ADDRESS_CITY,
    ADDRESS_COMPANY,
    ADDRESS_COMPANY_JOB_TITLE,
    ADDRESS_COMPANY_SCOPE, ADDRESS_COUNTRY, ADDRESS_EMAIL,
    ADDRESS_FIRSTNAME,
    ADDRESS_LASTNAME, ADDRESS_PHONE, ADDRESS_POSTCODE, ADDRESS_STATE, setAddressFields
} from "../AddressCard/actions";

export default function OrderCard(props) {

    const {order, dispatch, navigate, userData, userLocation, mixpanel} = props;

    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));
    const getLoadingCountries = useSelector((state) => isCountriesFetching(state.address));

    function handleClick() {

        const {line_items, billing, id} = order;

        line_items.forEach(item => {
            const meta_virtuals = order.meta_data.filter(item => item.key === 'has_virtuals'),
                arrayVirtuals = meta_virtuals ? meta_virtuals[0].value : [],
                virtual = (Array.isArray(arrayVirtuals)
                    ? arrayVirtuals.includes(item.product_id) || arrayVirtuals.includes(item.variation_id) : false);
            dispatch(
                addProduct(
                    item.product_id,
                    item.name,
                    String(item.price),
                    item.image.src,
                    virtual,
                    (item.variation_id === 0 ? null : item.variation_id),
                    null,
                ),
            );
            dispatch(
                setQuantity(
                    item.product_id,
                    (item.variation_id === 0 ? null : item.variation_id),
                    item.quantity
                )
            );
        });
        dispatch(setAddressFields(
        {
                [ADDRESS_FIRSTNAME]: billing.first_name,
                [ADDRESS_LASTNAME]: billing. last_name,
                [ADDRESS_COMPANY]: billing.company,
                [ADDRESS_COMPANY_JOB_TITLE]: billing.company_job_title,
                [ADDRESS_COMPANY_SCOPE]: billing.company_scope,
                [ADDRESS_ADDRESS]: billing.address_1,
                [ADDRESS_ADDINFO]: billing.address_2,
                [ADDRESS_COUNTRY]: billing.country,
                [ADDRESS_STATE]: billing.state,
                [ADDRESS_CITY]: billing.city,
                [ADDRESS_POSTCODE]: billing.postcode,
                [ADDRESS_PHONE]: billing.phone,
                [ADDRESS_EMAIL]: billing.email,
            }
        ));

        navigate("/cart");

        if (mixpanel)
            mixpanel.track('Order Again Button', { ...userLocation, ...userData, id });
    }

    return (
        <div>
            <Card centered color="orange" className="order-card">
                <Dimmer active={ [1, order.id].includes(getLoading) || getLoadingCountries } inverted>
                    <Loader className="order-update-loader"/>
                </Dimmer>
                <OrderCardHeader order={order} actionButtons={true} {...props}/>
                <MiniOrderCard
                    key={order.id}
                    order={order}
                    header={false}
                    circular={true}
                    actionButtons={true}
                    {...props}
                />
                {order.line_items.map((product) => (
                    <CartProduct
                        key={product.variation_id === 0 ? product.product_id : product.variation_id}
                        product={{
                            id: product.product_id,
                            variant_id: product.variant_id === 0 ? null : product.variant_id,
                            quantity: product.quantity,
                            image: product.image.src,
                            name: product.name,
                            price: String(product.price),
                            dateAdded: order.date_created,
                         }}
                        show_buttons={false}
                    />
                ))}
                <CardSummary
                    total_products={order.total}
                    total_shipping={order.shipping_total}
                    show_shipping = {order.shipping_lines.length > 0}
                />
                <Button fluid className="button-create-again" color="orange" onClick={handleClick}>
                    Повторить заказ
                </Button>
            </Card>
        </div>
    );
}