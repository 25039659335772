import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {
    Image, Menu, Input, Form, Icon, Button, Dropdown,
} from 'semantic-ui-react';
import config from '../../config/config';
import {
    openSearch, openAuth, closeSearch
} from './actions';
import { isAuthVisible } from './reducer';
import './NavBar.css';
import UserLogin from '../UserLogin/index';
import {getUserDisplayName} from '../UserLogin/reducer';
import {isMobile} from 'react-device-detect';
import {SEARCH_CONTEXTS, setSearchContext} from "../../views/Search/actions";
import {getToContext} from "../../views/Search/reducer";
import {closeMenu} from "../NavBottomBar/actions";

export default function NavTopBar(props) {

  const { searchVisible, dispatch, navigate, userData, userLocation, mixpanel, isLoggedIn } = props;

  const authVisible = useSelector((state) => isAuthVisible(state.navtopbar));
  const userNickName = useSelector((state) => getUserDisplayName(state.userLogin));
  const searchContext = useSelector((state) => getToContext(state.search));

  const [searchState, setSearchState] = useState({});

  useEffect(() => {
      setSearchState({
          search: '',
          context: searchContext,
      });
    }, [props]);

  function handleSubmit() {
      dispatch(closeSearch());
      dispatch(setSearchContext(searchState.context));
      navigate(`/search/${searchState.search}`, {state: {doQuery: true}});
      if (mixpanel)
          mixpanel.track('Search Confirm', { ...userLocation, ...userData, ...searchState });
  }

  function setSearch(e) {
      setSearchState({ ...searchState, search: e.target.value });
  }

  function handleOpenSearch() {
      dispatch(openSearch());
      if (mixpanel)
          mixpanel.track('Search Open', { ...userLocation, ...userData, ...searchState });
      dispatch(closeMenu());
  }

  function handleOpenAuth() {
      dispatch(openAuth());
      if (mixpanel)
          mixpanel.track('Auth Open Form', { ...userLocation, ...userData });
  }

  function handleSelectContext(e, data) {
      setSearchState({ ...searchState, context:data.value });
  }

  function goBack() {
      navigate( -1 );
  }

  const searchForm = () => (
        <Form onSubmit={handleSubmit} className="search-form">
            <Input
                type='search'
                placeholder='Я ищу...'
                name="search"
                className="search"
                value={searchState.search}
                onChange={setSearch}
                autoFocus={true}
                >
                <input className="search-field"/>
                <Dropdown
                    className="search-dropdown"
                    options={SEARCH_CONTEXTS}
                    value={searchState.context}
                    onChange={handleSelectContext}
                    />
                <Button className="search-button" type="submit" icon="search" />
            </Input>
        </Form>
  );

  return (
      <Menu fluid secondary fixed="top" id="nav-top-bar" className="nav-top-bar" color="blue" icon="labeled" inverted>
        <Menu.Item className="shop-name"
                   onClick={() => navigate("/")}
        >
            <Image src={config.SHOP_LOGO} size="tiny" />
            <p className="shop-text">{config.SHOP_NAME}</p>
        </Menu.Item>
          {searchVisible ? '' :
          <Menu.Item name="categories" className="shop-icon-item" position="right"
                     onClick={() => navigate("/categories/"+config.CATALOG_CATEGORIES_ID)}
          >
              <Icon name="browser" size="tiny" className="shop-icon" />
              <div className="shop-text-item">Каталог</div>
          </Menu.Item>
          }
        {searchVisible ? '' :
          <Menu.Item className="shop-icon-item"
                     onClick={handleOpenSearch}
          >
            <Icon name="search" size="tiny" className="shop-icon"/>
              <div className="shop-text-item">Поиск</div>
          </Menu.Item>
        }
        {!searchVisible && isLoggedIn ?
          <Menu.Item className="shop-icon-item">
            <Icon name="user" size="tiny" className="shop-icon" />
              <div className="shop-text-item">{userNickName}</div>
          </Menu.Item>
            : ''}
        {!searchVisible && !isLoggedIn ?
          <Menu.Item className="shop-icon-item"
                     onClick={handleOpenAuth}
          >
            <Icon name="sign in" size="tiny" className="shop-icon"/>
              <div className="shop-text-item">Войти</div>
          </Menu.Item>
            : null}
        <UserLogin showModal={authVisible} {...props}/>
        {searchVisible ?
            <Menu.Item className="menu-search-item">
               {searchForm()}
            </Menu.Item> : ''
        }
        {searchVisible || isMobile ? '' :
            <Menu.Item className="shop-icon-item"
                       onClick={goBack}
            >
                <Icon name="step backward" size="tiny" className="back-icon"/>
                <div className="shop-text-item">Назад</div>
            </Menu.Item>
        }
      </Menu>
    );
}
//Здесь был Саша
